var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-consultation-cta-container",class:{ large: _vm.size === 'large' }},[(!_vm.$isAMP)?_c('v-btn',{staticClass:"noselect",attrs:{"large":"","color":"primary","nuxt":""},on:{"click":function($event){return _vm.startNewConsultation()}}},[_c('i',{staticClass:"me-2 v-icon icon-plus"}),_vm._v("\n    "+_vm._s(_vm.$t('consultation.newCta'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.$isAMP)?_c('v-btn',{staticClass:"noselect",attrs:{"large":"","color":"primary","nuxt":"","to":_vm.localePath({
        name: 'account-login',
        query: {
          redirect_uri: _vm.localePath({
            name: 'new',
            query: { source: _vm.source },
          }),
        },
      })}},[_c('i',{staticClass:"me-2 v-icon icon-plus"}),_vm._v("\n    "+_vm._s(_vm.$t('consultation.newCta'))+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }