<template>
  <div
    v-if="!permissionGranted"
    class="notification-bar green white--text text-center"
  >
    {{ $t('notifications.notificationBar1') }}
    <v-btn
      text
      class="d-inline-block ma-0 pa-0"
      @click="initiateDialogue()"
    >
      {{ $t('notifications.notificationBar2') }}
    </v-btn>
  </div>
</template>

<script>
import { trackEvent } from '@/utils/tracking'

export default {
  name: 'NotificationBar',
  data () {
    return {
      permissionGranted: true
    }
  },
  mounted () {
    if (!window.navigator.userAgent.match(/iPhone/i) && !window.navigator.userAgent.match(/iPad/i)) {
      if (window.Notification.permission !== 'granted' && window.Notification.permission !== 'denied') {
        this.permissionGranted = false
      }
    }
  },
  methods: {
    async initiateDialogue () {
      trackEvent('Web notifications dialogue initiated', {}, this.$auth.user)
      const permission = await Notification.requestPermission()
      this.permissionGranted = permission === 'granted'
      if (this.permissionGranted) {
        trackEvent('Web notifications accepted', {}, this.$auth.user)
      } else {
        trackEvent('Web notifications refused', {}, this.$auth.user)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.notification-bar{
  font-size: 0.875rem;
  .v-btn__content{
    text-decoration: underline;
  }
}
</style>
<style lang="scss">
.notification-bar{
  .v-btn__content{
    text-decoration: underline;
  }
}
</style>
