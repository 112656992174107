<template>
  <div v-if="items.length" class="breadcrumbs-container">
    <i
      class="ms-3 me-3 v-icon icon-small icon-home"
    />
    <v-breadcrumbs
      :items="items"
      :divider="divider"
      class="pa-0 ma-4 mt-5 ms-10"
    />
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',

  props: {
    items: {
      type: Array,
      required: true
    },
    divider: {
      type: String,
      required: false,
      default: '/'
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumbs-container {
  position: relative;
}
.v-icon {
  position: absolute!important;
  top: 0px;
}
</style>

<style lang="scss">
.v-breadcrumbs__item--disabled {
  color: #595959!important;
}
</style>
