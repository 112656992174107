
// middleware/setFirstVisitTime.js
const cookie = require('cookie')

export default function (req, res, next) {
  const cookies = cookie.parse(req.headers.cookie || '')
  if (!cookies.firstVisitTime) {
    const now = Date.now().toString()
    res.setHeader('Set-Cookie', cookie.serialize('firstVisitTime', now, {
      maxAge: 60 * 60, // 1 day in seconds
      path: '/'
    }))
  }
  next()
}
