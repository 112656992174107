export function truncateText (string, maxLength) {
  if (!string) {
    return null
  }
  return string.length > maxLength ? string.substr(0, maxLength) + '…' : string
}

export function sanitize (string) {
  const lt = /</g
  const gt = />/g
  const ap = /'/g
  const ic = /"/g
  return string
    .toString()
    .replace(lt, '&lt;')
    .replace(gt, '&gt;')
    .replace(ap, '&#39;')
    .replace(ic, '&#34;')
}

export function download (response) {
  const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))
  const link = document.createElement('a')
  const contentDisposition = response.headers['content-disposition']

  let fileName = 'unknown'
  if (contentDisposition) {
    let fileNameMatch = contentDisposition.match(/filename="(.+)"/)
    if (!fileNameMatch) {
      fileNameMatch = contentDisposition.match(/filename=(.+)/)
      if (fileNameMatch.length === 2) {
        fileName = fileNameMatch[1]
      }
    } else if (fileNameMatch.length === 2) {
      fileName = fileNameMatch[1]
    }
  }
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)

  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

export function processWithParagraphs (string) {
  if (!string) {
    return null
  }
  return sanitize(string)
    .split('\r\n')
    .join('<br>')
    .split('\n')
    .join('<br>')
}

export function getProperLabel (number, type) {
  if (!type) {
    return number
  }
  switch (true) {
    case number === 0:
      return `counters.0${type}`
    case number === 1:
      return `counters.1${type}`
    case number === 2:
      return `counters.2${type}`
    case number > 2 && number <= 10:
      return `counters.3to10${type}`
    case number > 10:
      return `counters.10plus${type}`
  }
}

export function getHashParameters (hash) {
  return hash.split('&').reduce(function (result, item) {
    const parts = item.split('=')
    result[parts[0]] = parts[1]
    return result
  }, {})
}

export function shortFormatNumber (num, digits) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export function scrollToFirstError () {
  if (document.querySelector('.input--error')) {
    const domRect = document.querySelector('.input--error').getBoundingClientRect()
    window.scrollTo(
      domRect.left + document.documentElement.scrollLeft,
      domRect.top + document.documentElement.scrollTop
    )
  }
  if (document.querySelector('.error-msg')) {
    const domRect = document.querySelector('.error-msg').getBoundingClientRect()
    window.scrollTo(
      domRect.left + document.documentElement.scrollLeft,
      domRect.top + document.documentElement.scrollTop
    )
  }
}

export function scrollToElement (element) {
  if (document.querySelector(element)) {
    const domRect = document.querySelector(element).getBoundingClientRect()
    window.scrollTo(
      domRect.left + document.documentElement.scrollLeft,
      domRect.top - 30 + document.documentElement.scrollTop
    )
  }
}

export function genRandom () {
  return (
    Math.random()
      .toString(36)
      .substring(2, 10) +
    Math.random()
      .toString(36)
      .substring(2, 10)
  )
}

export function getCountryNameFromCountryCode (countryCode) {
  switch (countryCode) {
    case 'ma':
      return 'Morocco'
    case 'ae':
      return 'United Arab Emirates'
    case 'sa':
      return 'Saudi Arabia'
    case 'eg':
      return 'Egypt'
  }
}

export function validateEmail (email) {
  const format = /\S+@\S+\.\S+/
  return format.test(email)
}

export function isArabic (text) {
  const pattern = /[a-zA-Z]/
  const result = !pattern.test(text)
  return result
}

export function addParamsToLocation (params, path) {
  const start = Object.keys(params).length === 0 ? '' : '?'
  history.pushState(
    {},
    null,
    path +
      start +
      Object.keys(params)
        .map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        })
        .join('&')
  )
}

export async function asyncForEach (array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export function copyText (text) {
  const input = document.createElement('input')
  input.setAttribute('value', text)
  document.body.appendChild(input)
  input.select()
  const result = document.execCommand('copy')
  document.body.removeChild(input)
  return result
}

export function generateArrayOfYears () {
  const max = new Date().getFullYear()
  const min = max - 40
  const years = []

  for (let i = max; i >= min; i--) {
    years.push({ value: i, label: i })
  }
  return years
}

export function openFileFromBlob (responseData, fileName) {
  const url = window.URL.createObjectURL(new Blob([responseData]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export function convertStringToUTF8ByteArray (str) {
  const utf8 = []
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i)
    if (charcode < 0x80) {
      utf8.push(charcode)
    } else if (charcode < 0x800) {
      utf8.push(0xC0 | (charcode >> 6), 0x80 | (charcode & 0x3F))
    } else if (charcode < 0xD800 || charcode >= 0xE000) {
      utf8.push(0xE0 | (charcode >> 12), 0x80 | ((charcode >> 6) & 0x3F), 0x80 | (charcode & 0x3F))
    } else {
      i++
      charcode = 0x10000 + (((charcode & 0x3FF) << 10) | (str.charCodeAt(i) & 0x3FF))
      utf8.push(
        0xF0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3F),
        0x80 | ((charcode >> 6) & 0x3F),
        0x80 | (charcode & 0x3F)
      )
    }
  }
  return utf8
}
