<template>
  <v-app>
    <LazyHydrate when-visible>
      <Header />
    </LazyHydrate>
    <v-main>
      <v-container>
        <nuxt />
      </v-container>
    </v-main>
    <Footer />
    <NotificationsService />
  </v-app>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { convertStringToUTF8ByteArray } from '@/utils'
import Header from '@/components/Header'
import NotificationsService from '@/components/NotificationsService'
import Footer from '@/components/Footer'

const base64js = require('base64-js')

export default {
  name: 'Default',
  components: {
    LazyHydrate,
    Header,
    Footer,
    NotificationsService
  },
  data () {
    return {
      lang: this.$i18n.locale.substring(0, 2),
      market: this.$i18n.locale.substring(3, 5),
      i18nSeoFilteredLinks: [],
      i18nSeoFilteredMeta: []
    }
  },
  head () {
    this.i18nSeoFilteredLinks = []
    this.i18nSeoFilteredMeta = []
    const i18nSeo = this.$nuxtI18nSeo()
    const restrictToCurrentMarket =
      this.$route.name &&
      (this.$route.name.includes('consultations-id-slug') ||
        this.$route.name.includes('e-services-cases') ||
        this.$route.name.includes('e-services-rent') ||
        this.$route.name.includes('pages-good-conduct') ||
        this.$route.name.includes('pages-housing-certificate') ||
        this.$route.name.includes('users-id-username') ||
        this.$nuxt.$route.query.page ||
        this.$route.name.includes('experts-id-username'))
    if (!this.$isAMP) {
      if (i18nSeo && i18nSeo.link) {
        i18nSeo.link.forEach((link) => {
          if (link && link.href) {
            const linkMarket = link.href.substring(4, 6)
            const marketDefaultLang = this.$t('markets').filter(
              i => i.country === linkMarket
            )[0].default_language
            if (
              !link.hreflang ||
              (link.hreflang &&
                link.hreflang.length > 2 &&
                (linkMarket === this.market ||
                  (linkMarket !== this.market &&
                    !restrictToCurrentMarket &&
                    link.hreflang.substring(0, 2) === marketDefaultLang)))
            ) {
              link.href = `${process.env.SITE_URL}${link.href}`
              this.i18nSeoFilteredLinks.push(link)
            }
          }
        })
      }
      const manifest = {
        name: `${this.$t('globals.siteName')}`,
        short_name: `${this.$t('globals.siteName')}`,
        start_url: `${process.env.SITE_URL}/${this.lang}-${this.market}`,
        background_color: '#FFFFFF',
        theme_color: '#FFFFFF',
        display: 'standalone',
        icons: [
          {
            src: `${process.env.SITE_URL}/img/android-chrome-192x192.png?ver=382655`,
            sizes: '192x192',
            type: 'image/png'
          },
          {
            src: `${process.env.SITE_URL}/img/android-chrome-512x512.png?ver=382655`,
            sizes: '512x512',
            type: 'image/png'
          }
        ]
      }
      this.i18nSeoFilteredLinks.push({
        rel: 'manifest',
        href:
          'data:application/manifest+json;base64,' +
          base64js.fromByteArray(
            convertStringToUTF8ByteArray(JSON.stringify(manifest))
          )
      })
    }
    if (i18nSeo && i18nSeo.meta) {
      i18nSeo.meta.forEach((meta) => {
        if (meta && meta.content) {
          const metaMarket = meta.content.substring(3, 5)
          const marketDefaultLang = this.$t('markets').filter(
            i => i.country === metaMarket
          )[0].default_language
          if (
            metaMarket === this.market ||
            (metaMarket !== this.market &&
              !restrictToCurrentMarket &&
              meta.content.substring(0, 2) === marketDefaultLang)
          ) {
            this.i18nSeoFilteredMeta.push(meta)
          }
        }
      })
    }

    return {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs
      },
      link: [...this.i18nSeoFilteredLinks],
      meta: [...this.i18nSeoFilteredMeta]
    }
  },
  created () {
    if (this.$device.isMobileOrTablet) {
      this.$store.commit('setIsMobile', true)
    } else {
      this.$store.commit('setIsMobile', false)
    }
    if (!this.$i18n.locale) {
      this.$i18n.locale = 'ar-ma'
    }
    if (this.lang) {
      this.$vuetify.rtl = this.lang === 'ar'
    } else {
      this.$vuetify.rtl = true
    }
    this.$dayjs.locale(this.lang)
  },
  jsonld () {
    return [
      {
        '@context': 'http://schema.org/',
        '@type': 'WebSite',
        name: this.$t('globals.siteName'),
        alternateName: 'Mahkamaty',
        url: 'https://mahkamaty.com',
        image: 'https://mahkamaty.com/img/logo-dark.svg',
        sameAs: [
          'https://www.facebook.com/mahkamatyofficial',
          'https://twitter.com/mahkamaty',
          'https://www.linkedin.com/company/mahkamaty/'
        ],
        potentialAction: {
          '@type': 'SearchAction',
          target: `https://mahkamaty.com/${this.$i18n.locale}/consultations?search={search_term_string}`,
          'query-input': 'required name=search_term_string'
        }
      },
      {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Mahkamaty',
        legalName: 'Mahkamaty',
        url: 'https://mahkamaty.com',
        logo: 'https://mahkamaty.com/img/logo-dark.svg',
        foundingDate: '2015',
        founders: [
          {
            '@context': 'https://schema.org',
            '@type': 'Person',
            image: '/img/about/ayman.jpg',
            jobTitle: 'Mahkamaty Founder',
            name: 'Aymane Sennoussi',
            birthPlace: 'Kenitra, Morocco',
            birthDate: '1990-11-06',
            gender: 'male',
            nationality: 'Moroccan',
            url: 'https://mahkamaty.com',
            sameAs: [
              'https://www.linkedin.com/in/aymane-sennoussi/',
              'https://www.facebook.com/aysennoussi',
              'https://twitter.com/aysennoussi',
              'https://www.instagram.com/asennoussi/'
            ]
          }
        ],
        sameAs: [
          'https://www.facebook.com/mahkamatyofficial',
          'https://twitter.com/mahkamaty',
          'https://www.linkedin.com/company/mahkamaty/'
        ]
      }
    ]
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Dubai';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Dubai'), local('Dubai-Regular'),
    url('/fonts/dubai-regular.woff2') format('woff2'),
    url('/fonts/dubai-regular.woff') format('woff');
}
@font-face {
  font-family: 'Dubai';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Dubai Bold'), local('Dubai-Bold'),
    url('/fonts/dubai-bold.woff2') format('woff2'),
    url('/fonts/dubai-bold.woff') format('woff');
}
html[dir='rtl'] {
  scroll-behavior: smooth;
  font-size: 18px !important;
}
html[dir='ltr'] {
  scroll-behavior: smooth;
  font-size: 15px !important;
}
body {
  overflow: hidden;
}
.ltr {
  direction: ltr;
}
.rtl {
  direction: rtl;
}
.v-application {
  padding: 0;
  margin: 0;
  .container {
    padding: 0;
  }
  a {
    color: $default-text-color;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      color: $mahkamaty-blue-color-alt;
      text-decoration: underline;
    }
  }
  &.theme--light {
    color: $default-text-color;
    background-color: $page-background-color !important;
    button {
      color: $white-color;
    }
  }
  .v-btn.primary {
    background: $mahkamaty-blue-color !important;
    &:hover {
      background: $mahkamaty-blue-color-alt !important;
    }
  }
  .v-btn.warning {
    color: #502121;
    font-weight: bold;
  }
  .v-btn {
    text-transform: unset;
    letter-spacing: unset;
    font-weight: bold;
  }
  .accent-icon {
    color: $default-accent-color !important;
  }
  &--is-rtl {
    .v-input__slot {
      text-align: right;
    }
    .v-radio {
      margin-right: 0;
    }
  }
}
.subtitle-1 {
  line-height: 2em !important;
}

.v-slide-group__wrapper {
  touch-action: pan-y !important;
}

.v-slide-group__content,
.v-slide-group__wrapper {
  display: flex;
}
.icon-chevron-left:before,
.icon-chevron-right:before {
  font-weight: bold;
  font-size: 1.6rem;
}
.v-slide-group__prev,
.v-slide-group__next {
  position: absolute;
  top: 37%;
  z-index: 200 !important;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-weight: bold;
  background: $white-color;
  box-shadow: $standard-shadow;
  &--disabled {
    display: none !important;
  }
}
.v-slide-group__prev {
  left: -20px;
  right: unset;
}
.v-slide-group__next {
  right: -20px;
  left: unset;
}

.v-application--is-rtl {
  .v-slide-group__prev {
    right: -20px;
    left: unset;
  }
  .v-slide-group__next {
    left: -20px;
    right: unset;
  }
}
.no-hover {
  text-decoration: none !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
/* Give the footer a relative position for the logo */
.v-footer {
  position: relative !important;
}
.v-breadcrumbs li {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  vertical-align: top;
}
</style>
