<template>
  <div class="d-inline-block" style="padding: 18px">
    <nuxt-link
      v-for="locale in availableLocales"
      :key="locale.code"
      class="black--text"
      :to="switchLocalePath(locale.code)"
    >
      {{ locale.name }}
    </nuxt-link>
  </div>
</template>

<script>
import { trackEvent } from '@/utils/tracking'

export default {
  name: 'LangSwitcher',

  computed: {
    availableLocales () {
      return this.$i18n.locales.filter(
        i =>
          i.code !== this.$i18n.locale &&
          i.code.substring(3, 5) === this.$i18n.locale.substring(3, 5)
      )
    },
    lang () {
      return this.$i18n.locale.substring(0, 2)
    }
  },

  created () {
    this.$store.commit('setCurrentLang', this.lang)
    this.setTimeLocale(this.lang)
    this.setVuetifyRtl()
    this.$i18n.onLanguageSwitched = (oldLocale, newLocale) => {
      const newLang = newLocale.substring(0, 2)
      const oldLang = oldLocale.substring(0, 2)
      this.$store.commit('setCurrentLang', newLang)
      this.setTimeLocale(newLang)
      this.setVuetifyRtl()
      if (oldLang !== newLang) {
        trackEvent(
          'Language changed',
          { from_lang: oldLang, to_lang: newLang },
          this.$auth.user
        )
      }
    }
  },

  methods: {
    setTimeLocale (newLocale) {
      this.$dayjs.locale(newLocale)
    },
    setVuetifyRtl () {
      this.$vuetify.rtl = this.lang === 'ar'
    }
  }
}
</script>
