<template>
  <v-footer class="footer mt-12 body-2 pt-8 pb-8 white">
    <v-container>
      <Breadcrumbs
        v-show="$store.state.breadcrumbs.length && isMobile"
        class="mb-12 hidden-lg-and-up"
        :items="$store.state.breadcrumbs"
      />
      <Stats class="ma-0 mt-6 mb-6 hidden-lg-and-up" />
      <v-row>
        <v-col cols="12" class="mb-4 text-center text-lg-end">
          <LangSwitcher />
          <MarketSelector />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0 text-center">
          <Logo variant="footer-logo" :centered="true" class="mb-8 mb-lg-1" />
          {{ $t('globals.copyright') }}
        </v-col>
        <v-col cols="12" class="pt-0 pb-0 text-center">
          <span v-for="(link, index) in links" :key="link.id">
            <span
              v-show="currentMarket.country === 'ma' || link.path !== 'invite'"
            >
              <nuxt-link
                class="d-inline-block mt-1 mb-1"
                :to="localePath(link.path)"
              >{{ link.title }}</nuxt-link>
              <span
                v-if="links.length > 1 && index !== links.length - 1"
              >&nbsp;—&nbsp;
              </span>
            </span>
          </span>
        </v-col>
        <client-only>
          <v-col
            v-if="$auth.loggedIn && $auth.user.role === 'admin'"
            cols="12"
            class="mt-6 pt-0 pb-0 text-center"
          >
            <v-chip class="me-2" color="red" text-color="white">
              Admin
            </v-chip>
            <span v-for="(link, index) in adminLinks" :key="link.id">
              <nuxt-link :to="localePath(link.path)">
                {{ link.title }}
              </nuxt-link>
              <span
                v-if="adminLinks.length > 1 && index !== links.length - 1"
              >&nbsp;—&nbsp;</span>
            </span>
          </v-col>
        </client-only>
        <v-col cols="12" class="mt-6 text-center">
          <a
            class="social-link"
            href="https://www.facebook.com/mahkamatyofficial"
            target="_blank"
            rel="noopener"
            :title="$t('globals.onFacebook')"
          >
            <i class="v-icon icon-facebook" />
          </a>
          <a
            class="social-link"
            href="https://twitter.com/mahkamaty"
            target="_blank"
            rel="noopener"
            :title="$t('globals.onTwitter')"
          >
            <i class="v-icon icon-twitter" />
          </a>
          <a
            class="social-link"
            href="https://www.linkedin.com/company/mahkamaty"
            target="_blank"
            rel="noopener"
            :title="$t('globals.onLinkedIn')"
          >
            <i class="v-icon icon-linkedin" />
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import Logo from '@/components/Logo'
import Breadcrumbs from '@/components/Breadcrumbs'
import MarketSelector from '@/components/MarketSelector'
import LangSwitcher from '@/components/LangSwitcher'
const Stats = () => import('@/components/Stats')

export default {
  components: {
    Logo,
    Stats,
    Breadcrumbs,
    MarketSelector,
    LangSwitcher
  },

  data () {
    return {
      links: [
        {
          id: 0,
          path: 'invite',
          title: this.$t('nav.invite')
        },
        {
          id: 1,
          path: 'experts-signup',
          title: this.$t('expert.signup')
        },
        {
          id: 2,
          path: 'about-us',
          title: this.$t('aboutUs.title')
        },
        {
          id: 3,
          path: 'terms-and-privacy-policy',
          title: this.$t('globals.terms')
        }
      ],
      adminLinks: [
        {
          id: 4,
          path: 'admin-experts',
          title: this.$t('nav.adminExperts')
        }
      ],
      clientLoaded: false
    }
  },

  computed: {
    isMobile () {
      return this.$store.state.isMobile
    },
    currentMarket () {
      return this.$t('markets').filter(
        i => i.country === this.$i18n.locale.substring(3, 5)
      )[0]
    }
  },

  mounted () {
    this.clientLoaded = true
  }
}
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid $border-color !important;
  z-index: 2;
}
.social-link:hover {
  text-decoration: none !important;
}
a {
  color: #4b586c !important;
}
</style>
