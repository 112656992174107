<template>
  <nuxt-link
    :to="localePath({ name: 'index' })"
    class="logo mt-2 d-inline-block"
    :class="{
      'ms-2 mb-0': isMobile && variant !== 'footer-logo',
      'footer-logo': !isMobile && variant === 'footer-logo',
      relative: variant !== 'footer-logo',
      centered: centered,
      'd-block': isMobile && variant === 'footer-logo',
    }"
  >
    <img
      v-if="!$isAMP && !centered"
      :src="`/img/flags/${currentMarket.country}.svg`"
      :alt="currentMarket.desc"
      :title="currentMarket.desc"
      class="flag"
      :class="{ left: $vuetify.rtl, right: !$vuetify.rtl }"
    >
    <img
      v-if="!$isAMP"
      :title="
        $t('home.h1', {
          market: currentMarket.countryName,
        })
      "
      alt="محكمتي - Mahkamaty"
      src="/img/logo-dark.svg"
      class="d-block"
    >
    <amp-img
      v-if="$isAMP && !centered"
      width="25"
      height="14"
      layout="fixed"
      class="logo-flag"
      :alt="currentMarket.desc"
      :title="currentMarket.desc"
      :class="{ left: $vuetify.rtl, right: !$vuetify.rtl }"
      :src="`/img/flags/${currentMarket.country}.svg`"
    />
    <amp-img
      v-if="$isAMP"
      width="151"
      height="50"
      layout="fixed"
      alt="محكمتي - Mahkamaty"
      :title="$t('globals.backToHome')"
      src="/img/logo-dark.svg"
    />
  </nuxt-link>
</template>

<script>
export default {
  name: 'Logo',

  props: {
    variant: {
      type: String,
      required: false,
      default: 'light'
    },
    centered: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    isMobile () {
      return this.$store.state.isMobile
    },
    currentMarket () {
      return this.$t('markets').find(
        i => i.country === this.$i18n.locale.substring(3, 5)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
.logo {
  amp-img,
  img {
    height: 50px;
    width: 151px;
  }
  &.centered {
    margin: 0 auto;
    amp-img,
    img {
      margin: 0 auto;
    }
  }
  .flag {
    height: 14px;
    width: 25px;
    position: absolute;
    &.left {
      left: 0px;
    }
    &.right {
      right: 0px;
    }
  }
  &.footer-logo {
    img {
      position: absolute;
      right: 0;
      left: 0;
      top: 38px;
    }
  }
}
</style>
