<template>
  <v-container>
    <Snackbar :message="$store.state.snackbarMsg" />
    <v-row no-gutters align="center" justify="center">
      <v-col class="pa-0 mt-8 mb-0 text-center" cols="12">
        <div class="logo">
          <Logo />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Snackbar from '@/components/Snackbar'
import Logo from '@/components/Logo'

export default {
  components: {
    Logo,
    Snackbar
  }
}
</script>

<style lang="scss" scoped>
.logo {
  height: 50px;
  width: 151px;
  display: inline-block;
}
</style>
