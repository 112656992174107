import dayjs from 'dayjs'

import 'dayjs/locale/ar'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'

dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/relativeTime'))

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
