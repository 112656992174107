import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d71b4702 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a8c8d46c = () => interopDefault(import('../pages/tag/index.vue' /* webpackChunkName: "pages/tag/index" */))
const _c5a01b7e = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _33589bc6 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _41b8bee2 = () => interopDefault(import('../pages/case-study.vue' /* webpackChunkName: "pages/case-study" */))
const _50f94cec = () => interopDefault(import('../pages/consultation.vue' /* webpackChunkName: "pages/consultation" */))
const _3175732c = () => interopDefault(import('../pages/e-services/index.vue' /* webpackChunkName: "pages/e-services/index" */))
const _2674d32f = () => interopDefault(import('../pages/invite/index.vue' /* webpackChunkName: "pages/invite/index" */))
const _7151478d = () => interopDefault(import('../pages/new.vue' /* webpackChunkName: "pages/new" */))
const _c49e2ed6 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _69a40d58 = () => interopDefault(import('../pages/pay/index.vue' /* webpackChunkName: "pages/pay/index" */))
const _78b8dbef = () => interopDefault(import('../pages/read-notification.vue' /* webpackChunkName: "pages/read-notification" */))
const _ef5c6694 = () => interopDefault(import('../pages/terms-and-privacy-policy.vue' /* webpackChunkName: "pages/terms-and-privacy-policy" */))
const _0ff02178 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _5c178f52 = () => interopDefault(import('../pages/account/choose-username.vue' /* webpackChunkName: "pages/account/choose-username" */))
const _2566cd8b = () => interopDefault(import('../pages/account/email-confirmation.vue' /* webpackChunkName: "pages/account/email-confirmation" */))
const _a0095598 = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _411a711e = () => interopDefault(import('../pages/account/reset-password.vue' /* webpackChunkName: "pages/account/reset-password" */))
const _c86d8346 = () => interopDefault(import('../pages/account/signup.vue' /* webpackChunkName: "pages/account/signup" */))
const _2d3e5814 = () => interopDefault(import('../pages/admin/experts.vue' /* webpackChunkName: "pages/admin/experts" */))
const _eabad5c6 = () => interopDefault(import('../pages/e-services/cases.vue' /* webpackChunkName: "pages/e-services/cases" */))
const _72d3584f = () => interopDefault(import('../pages/e-services/rent.vue' /* webpackChunkName: "pages/e-services/rent" */))
const _154006ee = () => interopDefault(import('../pages/e-services/rent-appartment.vue' /* webpackChunkName: "pages/e-services/rent-appartment" */))
const _279d3f81 = () => interopDefault(import('../pages/experts/signup.vue' /* webpackChunkName: "pages/experts/signup" */))
const _ffc9fc12 = () => interopDefault(import('../pages/pages/free-consultations.vue' /* webpackChunkName: "pages/pages/free-consultations" */))
const _5c8e5bc2 = () => interopDefault(import('../pages/pages/good-conduct.vue' /* webpackChunkName: "pages/pages/good-conduct" */))
const _70580f09 = () => interopDefault(import('../pages/pages/housing-certificate.vue' /* webpackChunkName: "pages/pages/housing-certificate" */))
const _219172a6 = () => interopDefault(import('../pages/users/redirect/user/_username/index.vue' /* webpackChunkName: "pages/users/redirect/user/_username/index" */))
const _24bea744 = () => interopDefault(import('../pages/users/redirect/user/_username/_section/index.vue' /* webpackChunkName: "pages/users/redirect/user/_username/_section/index" */))
const _4d01a46c = () => interopDefault(import('../pages/consultations/filter/_type.vue' /* webpackChunkName: "pages/consultations/filter/_type" */))
const _397a82cc = () => interopDefault(import('../pages/invite/_id/index.vue' /* webpackChunkName: "pages/invite/_id/index" */))
const _75aa338e = () => interopDefault(import('../pages/pay/_reference/index.vue' /* webpackChunkName: "pages/pay/_reference/index" */))
const _dd4b8dd4 = () => interopDefault(import('../pages/cases/_id/_slug.vue' /* webpackChunkName: "pages/cases/_id/_slug" */))
const _62507f9d = () => interopDefault(import('../pages/consultations/_id/_slug.vue' /* webpackChunkName: "pages/consultations/_id/_slug" */))
const _d65ae472 = () => interopDefault(import('../pages/experts/_id/_username.vue' /* webpackChunkName: "pages/experts/_id/_username" */))
const _086043c6 = () => interopDefault(import('../pages/users/_id/_username.vue' /* webpackChunkName: "pages/users/_id/_username" */))
const _a94d3a3c = () => interopDefault(import('../pages/tag/_keyword.vue' /* webpackChunkName: "pages/tag/_keyword" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ar-ae",
    component: _d71b4702,
    meta: {},
    alias: ["/amp/ar-ae"],
    name: "index___ar-ae"
  }, {
    path: "/ar-eg",
    component: _d71b4702,
    meta: {},
    alias: ["/amp/ar-eg"],
    name: "index___ar-eg"
  }, {
    path: "/ar-ma",
    component: _d71b4702,
    meta: {},
    alias: ["/amp/ar-ma"],
    name: "index___ar-ma"
  }, {
    path: "/ar-sa",
    component: _d71b4702,
    meta: {},
    alias: ["/amp/ar-sa"],
    name: "index___ar-sa"
  }, {
    path: "/en-ae",
    component: _d71b4702,
    meta: {},
    alias: ["/amp/en-ae"],
    name: "index___en-ae"
  }, {
    path: "/en-eg",
    component: _d71b4702,
    meta: {},
    alias: ["/amp/en-eg"],
    name: "index___en-eg"
  }, {
    path: "/en-sa",
    component: _d71b4702,
    meta: {},
    alias: ["/amp/en-sa"],
    name: "index___en-sa"
  }, {
    path: "/fr-ma",
    component: _d71b4702,
    meta: {},
    alias: ["/amp/fr-ma"],
    name: "index___fr-ma"
  }, {
    path: "/tag",
    component: _a8c8d46c,
    meta: {},
    alias: ["/amp/tag"],
    name: "tag"
  }, {
    path: "/ar-ae/about-us",
    component: _c5a01b7e,
    meta: {},
    alias: ["/amp/ar-ae/about-us"],
    name: "about-us___ar-ae"
  }, {
    path: "/ar-ae/account",
    component: _33589bc6,
    meta: {},
    alias: ["/amp/ar-ae/account"],
    name: "account___ar-ae"
  }, {
    path: "/ar-ae/case-study",
    component: _41b8bee2,
    meta: {},
    alias: ["/amp/ar-ae/case-study"],
    name: "case-study___ar-ae"
  }, {
    path: "/ar-ae/consultation",
    component: _50f94cec,
    meta: {},
    alias: ["/amp/ar-ae/consultation"],
    name: "consultation___ar-ae"
  }, {
    path: "/ar-ae/e-services",
    component: _3175732c,
    meta: {},
    alias: ["/amp/ar-ae/e-services"],
    name: "e-services___ar-ae"
  }, {
    path: "/ar-ae/invite",
    component: _2674d32f,
    meta: {},
    alias: ["/amp/ar-ae/invite"],
    name: "invite___ar-ae"
  }, {
    path: "/ar-ae/new",
    component: _7151478d,
    meta: {},
    alias: ["/amp/ar-ae/new"],
    name: "new___ar-ae"
  }, {
    path: "/ar-ae/notifications",
    component: _c49e2ed6,
    meta: {},
    alias: ["/amp/ar-ae/notifications"],
    name: "notifications___ar-ae"
  }, {
    path: "/ar-ae/pay",
    component: _69a40d58,
    meta: {},
    alias: ["/amp/ar-ae/pay"],
    name: "pay___ar-ae"
  }, {
    path: "/ar-ae/read-notification",
    component: _78b8dbef,
    meta: {},
    alias: ["/amp/ar-ae/read-notification"],
    name: "read-notification___ar-ae"
  }, {
    path: "/ar-ae/terms-and-privacy-policy",
    component: _ef5c6694,
    meta: {},
    alias: ["/amp/ar-ae/terms-and-privacy-policy"],
    name: "terms-and-privacy-policy___ar-ae"
  }, {
    path: "/ar-ae/users",
    component: _0ff02178,
    meta: {},
    alias: ["/amp/ar-ae/users"],
    name: "users___ar-ae"
  }, {
    path: "/ar-eg/about-us",
    component: _c5a01b7e,
    meta: {},
    alias: ["/amp/ar-eg/about-us"],
    name: "about-us___ar-eg"
  }, {
    path: "/ar-eg/account",
    component: _33589bc6,
    meta: {},
    alias: ["/amp/ar-eg/account"],
    name: "account___ar-eg"
  }, {
    path: "/ar-eg/case-study",
    component: _41b8bee2,
    meta: {},
    alias: ["/amp/ar-eg/case-study"],
    name: "case-study___ar-eg"
  }, {
    path: "/ar-eg/consultation",
    component: _50f94cec,
    meta: {},
    alias: ["/amp/ar-eg/consultation"],
    name: "consultation___ar-eg"
  }, {
    path: "/ar-eg/e-services",
    component: _3175732c,
    meta: {},
    alias: ["/amp/ar-eg/e-services"],
    name: "e-services___ar-eg"
  }, {
    path: "/ar-eg/invite",
    component: _2674d32f,
    meta: {},
    alias: ["/amp/ar-eg/invite"],
    name: "invite___ar-eg"
  }, {
    path: "/ar-eg/new",
    component: _7151478d,
    meta: {},
    alias: ["/amp/ar-eg/new"],
    name: "new___ar-eg"
  }, {
    path: "/ar-eg/notifications",
    component: _c49e2ed6,
    meta: {},
    alias: ["/amp/ar-eg/notifications"],
    name: "notifications___ar-eg"
  }, {
    path: "/ar-eg/pay",
    component: _69a40d58,
    meta: {},
    alias: ["/amp/ar-eg/pay"],
    name: "pay___ar-eg"
  }, {
    path: "/ar-eg/read-notification",
    component: _78b8dbef,
    meta: {},
    alias: ["/amp/ar-eg/read-notification"],
    name: "read-notification___ar-eg"
  }, {
    path: "/ar-eg/terms-and-privacy-policy",
    component: _ef5c6694,
    meta: {},
    alias: ["/amp/ar-eg/terms-and-privacy-policy"],
    name: "terms-and-privacy-policy___ar-eg"
  }, {
    path: "/ar-eg/users",
    component: _0ff02178,
    meta: {},
    alias: ["/amp/ar-eg/users"],
    name: "users___ar-eg"
  }, {
    path: "/ar-ma/about-us",
    component: _c5a01b7e,
    meta: {},
    alias: ["/amp/ar-ma/about-us"],
    name: "about-us___ar-ma"
  }, {
    path: "/ar-ma/account",
    component: _33589bc6,
    meta: {},
    alias: ["/amp/ar-ma/account"],
    name: "account___ar-ma"
  }, {
    path: "/ar-ma/case-study",
    component: _41b8bee2,
    meta: {},
    alias: ["/amp/ar-ma/case-study"],
    name: "case-study___ar-ma"
  }, {
    path: "/ar-ma/consultation",
    component: _50f94cec,
    meta: {},
    alias: ["/amp/ar-ma/consultation"],
    name: "consultation___ar-ma"
  }, {
    path: "/ar-ma/e-services",
    component: _3175732c,
    meta: {},
    alias: ["/amp/ar-ma/e-services"],
    name: "e-services___ar-ma"
  }, {
    path: "/ar-ma/invite",
    component: _2674d32f,
    meta: {},
    alias: ["/amp/ar-ma/invite"],
    name: "invite___ar-ma"
  }, {
    path: "/ar-ma/new",
    component: _7151478d,
    meta: {},
    alias: ["/amp/ar-ma/new"],
    name: "new___ar-ma"
  }, {
    path: "/ar-ma/notifications",
    component: _c49e2ed6,
    meta: {},
    alias: ["/amp/ar-ma/notifications"],
    name: "notifications___ar-ma"
  }, {
    path: "/ar-ma/pay",
    component: _69a40d58,
    meta: {},
    alias: ["/amp/ar-ma/pay"],
    name: "pay___ar-ma"
  }, {
    path: "/ar-ma/read-notification",
    component: _78b8dbef,
    meta: {},
    alias: ["/amp/ar-ma/read-notification"],
    name: "read-notification___ar-ma"
  }, {
    path: "/ar-ma/terms-and-privacy-policy",
    component: _ef5c6694,
    meta: {},
    alias: ["/amp/ar-ma/terms-and-privacy-policy"],
    name: "terms-and-privacy-policy___ar-ma"
  }, {
    path: "/ar-ma/users",
    component: _0ff02178,
    meta: {},
    alias: ["/amp/ar-ma/users"],
    name: "users___ar-ma"
  }, {
    path: "/ar-sa/about-us",
    component: _c5a01b7e,
    meta: {},
    alias: ["/amp/ar-sa/about-us"],
    name: "about-us___ar-sa"
  }, {
    path: "/ar-sa/account",
    component: _33589bc6,
    meta: {},
    alias: ["/amp/ar-sa/account"],
    name: "account___ar-sa"
  }, {
    path: "/ar-sa/case-study",
    component: _41b8bee2,
    meta: {},
    alias: ["/amp/ar-sa/case-study"],
    name: "case-study___ar-sa"
  }, {
    path: "/ar-sa/consultation",
    component: _50f94cec,
    meta: {},
    alias: ["/amp/ar-sa/consultation"],
    name: "consultation___ar-sa"
  }, {
    path: "/ar-sa/e-services",
    component: _3175732c,
    meta: {},
    alias: ["/amp/ar-sa/e-services"],
    name: "e-services___ar-sa"
  }, {
    path: "/ar-sa/invite",
    component: _2674d32f,
    meta: {},
    alias: ["/amp/ar-sa/invite"],
    name: "invite___ar-sa"
  }, {
    path: "/ar-sa/new",
    component: _7151478d,
    meta: {},
    alias: ["/amp/ar-sa/new"],
    name: "new___ar-sa"
  }, {
    path: "/ar-sa/notifications",
    component: _c49e2ed6,
    meta: {},
    alias: ["/amp/ar-sa/notifications"],
    name: "notifications___ar-sa"
  }, {
    path: "/ar-sa/pay",
    component: _69a40d58,
    meta: {},
    alias: ["/amp/ar-sa/pay"],
    name: "pay___ar-sa"
  }, {
    path: "/ar-sa/read-notification",
    component: _78b8dbef,
    meta: {},
    alias: ["/amp/ar-sa/read-notification"],
    name: "read-notification___ar-sa"
  }, {
    path: "/ar-sa/terms-and-privacy-policy",
    component: _ef5c6694,
    meta: {},
    alias: ["/amp/ar-sa/terms-and-privacy-policy"],
    name: "terms-and-privacy-policy___ar-sa"
  }, {
    path: "/ar-sa/users",
    component: _0ff02178,
    meta: {},
    alias: ["/amp/ar-sa/users"],
    name: "users___ar-sa"
  }, {
    path: "/en-ae/about-us",
    component: _c5a01b7e,
    meta: {},
    alias: ["/amp/en-ae/about-us"],
    name: "about-us___en-ae"
  }, {
    path: "/en-ae/account",
    component: _33589bc6,
    meta: {},
    alias: ["/amp/en-ae/account"],
    name: "account___en-ae"
  }, {
    path: "/en-ae/case-study",
    component: _41b8bee2,
    meta: {},
    alias: ["/amp/en-ae/case-study"],
    name: "case-study___en-ae"
  }, {
    path: "/en-ae/consultation",
    component: _50f94cec,
    meta: {},
    alias: ["/amp/en-ae/consultation"],
    name: "consultation___en-ae"
  }, {
    path: "/en-ae/e-services",
    component: _3175732c,
    meta: {},
    alias: ["/amp/en-ae/e-services"],
    name: "e-services___en-ae"
  }, {
    path: "/en-ae/invite",
    component: _2674d32f,
    meta: {},
    alias: ["/amp/en-ae/invite"],
    name: "invite___en-ae"
  }, {
    path: "/en-ae/new",
    component: _7151478d,
    meta: {},
    alias: ["/amp/en-ae/new"],
    name: "new___en-ae"
  }, {
    path: "/en-ae/notifications",
    component: _c49e2ed6,
    meta: {},
    alias: ["/amp/en-ae/notifications"],
    name: "notifications___en-ae"
  }, {
    path: "/en-ae/pay",
    component: _69a40d58,
    meta: {},
    alias: ["/amp/en-ae/pay"],
    name: "pay___en-ae"
  }, {
    path: "/en-ae/read-notification",
    component: _78b8dbef,
    meta: {},
    alias: ["/amp/en-ae/read-notification"],
    name: "read-notification___en-ae"
  }, {
    path: "/en-ae/terms-and-privacy-policy",
    component: _ef5c6694,
    meta: {},
    alias: ["/amp/en-ae/terms-and-privacy-policy"],
    name: "terms-and-privacy-policy___en-ae"
  }, {
    path: "/en-ae/users",
    component: _0ff02178,
    meta: {},
    alias: ["/amp/en-ae/users"],
    name: "users___en-ae"
  }, {
    path: "/en-eg/about-us",
    component: _c5a01b7e,
    meta: {},
    alias: ["/amp/en-eg/about-us"],
    name: "about-us___en-eg"
  }, {
    path: "/en-eg/account",
    component: _33589bc6,
    meta: {},
    alias: ["/amp/en-eg/account"],
    name: "account___en-eg"
  }, {
    path: "/en-eg/case-study",
    component: _41b8bee2,
    meta: {},
    alias: ["/amp/en-eg/case-study"],
    name: "case-study___en-eg"
  }, {
    path: "/en-eg/consultation",
    component: _50f94cec,
    meta: {},
    alias: ["/amp/en-eg/consultation"],
    name: "consultation___en-eg"
  }, {
    path: "/en-eg/e-services",
    component: _3175732c,
    meta: {},
    alias: ["/amp/en-eg/e-services"],
    name: "e-services___en-eg"
  }, {
    path: "/en-eg/invite",
    component: _2674d32f,
    meta: {},
    alias: ["/amp/en-eg/invite"],
    name: "invite___en-eg"
  }, {
    path: "/en-eg/new",
    component: _7151478d,
    meta: {},
    alias: ["/amp/en-eg/new"],
    name: "new___en-eg"
  }, {
    path: "/en-eg/notifications",
    component: _c49e2ed6,
    meta: {},
    alias: ["/amp/en-eg/notifications"],
    name: "notifications___en-eg"
  }, {
    path: "/en-eg/pay",
    component: _69a40d58,
    meta: {},
    alias: ["/amp/en-eg/pay"],
    name: "pay___en-eg"
  }, {
    path: "/en-eg/read-notification",
    component: _78b8dbef,
    meta: {},
    alias: ["/amp/en-eg/read-notification"],
    name: "read-notification___en-eg"
  }, {
    path: "/en-eg/terms-and-privacy-policy",
    component: _ef5c6694,
    meta: {},
    alias: ["/amp/en-eg/terms-and-privacy-policy"],
    name: "terms-and-privacy-policy___en-eg"
  }, {
    path: "/en-eg/users",
    component: _0ff02178,
    meta: {},
    alias: ["/amp/en-eg/users"],
    name: "users___en-eg"
  }, {
    path: "/en-sa/about-us",
    component: _c5a01b7e,
    meta: {},
    alias: ["/amp/en-sa/about-us"],
    name: "about-us___en-sa"
  }, {
    path: "/en-sa/account",
    component: _33589bc6,
    meta: {},
    alias: ["/amp/en-sa/account"],
    name: "account___en-sa"
  }, {
    path: "/en-sa/case-study",
    component: _41b8bee2,
    meta: {},
    alias: ["/amp/en-sa/case-study"],
    name: "case-study___en-sa"
  }, {
    path: "/en-sa/consultation",
    component: _50f94cec,
    meta: {},
    alias: ["/amp/en-sa/consultation"],
    name: "consultation___en-sa"
  }, {
    path: "/en-sa/e-services",
    component: _3175732c,
    meta: {},
    alias: ["/amp/en-sa/e-services"],
    name: "e-services___en-sa"
  }, {
    path: "/en-sa/invite",
    component: _2674d32f,
    meta: {},
    alias: ["/amp/en-sa/invite"],
    name: "invite___en-sa"
  }, {
    path: "/en-sa/new",
    component: _7151478d,
    meta: {},
    alias: ["/amp/en-sa/new"],
    name: "new___en-sa"
  }, {
    path: "/en-sa/notifications",
    component: _c49e2ed6,
    meta: {},
    alias: ["/amp/en-sa/notifications"],
    name: "notifications___en-sa"
  }, {
    path: "/en-sa/pay",
    component: _69a40d58,
    meta: {},
    alias: ["/amp/en-sa/pay"],
    name: "pay___en-sa"
  }, {
    path: "/en-sa/read-notification",
    component: _78b8dbef,
    meta: {},
    alias: ["/amp/en-sa/read-notification"],
    name: "read-notification___en-sa"
  }, {
    path: "/en-sa/terms-and-privacy-policy",
    component: _ef5c6694,
    meta: {},
    alias: ["/amp/en-sa/terms-and-privacy-policy"],
    name: "terms-and-privacy-policy___en-sa"
  }, {
    path: "/en-sa/users",
    component: _0ff02178,
    meta: {},
    alias: ["/amp/en-sa/users"],
    name: "users___en-sa"
  }, {
    path: "/fr-ma/about-us",
    component: _c5a01b7e,
    meta: {},
    alias: ["/amp/fr-ma/about-us"],
    name: "about-us___fr-ma"
  }, {
    path: "/fr-ma/account",
    component: _33589bc6,
    meta: {},
    alias: ["/amp/fr-ma/account"],
    name: "account___fr-ma"
  }, {
    path: "/fr-ma/case-study",
    component: _41b8bee2,
    meta: {},
    alias: ["/amp/fr-ma/case-study"],
    name: "case-study___fr-ma"
  }, {
    path: "/fr-ma/consultation",
    component: _50f94cec,
    meta: {},
    alias: ["/amp/fr-ma/consultation"],
    name: "consultation___fr-ma"
  }, {
    path: "/fr-ma/e-services",
    component: _3175732c,
    meta: {},
    alias: ["/amp/fr-ma/e-services"],
    name: "e-services___fr-ma"
  }, {
    path: "/fr-ma/invite",
    component: _2674d32f,
    meta: {},
    alias: ["/amp/fr-ma/invite"],
    name: "invite___fr-ma"
  }, {
    path: "/fr-ma/new",
    component: _7151478d,
    meta: {},
    alias: ["/amp/fr-ma/new"],
    name: "new___fr-ma"
  }, {
    path: "/fr-ma/notifications",
    component: _c49e2ed6,
    meta: {},
    alias: ["/amp/fr-ma/notifications"],
    name: "notifications___fr-ma"
  }, {
    path: "/fr-ma/pay",
    component: _69a40d58,
    meta: {},
    alias: ["/amp/fr-ma/pay"],
    name: "pay___fr-ma"
  }, {
    path: "/fr-ma/read-notification",
    component: _78b8dbef,
    meta: {},
    alias: ["/amp/fr-ma/read-notification"],
    name: "read-notification___fr-ma"
  }, {
    path: "/fr-ma/terms-and-privacy-policy",
    component: _ef5c6694,
    meta: {},
    alias: ["/amp/fr-ma/terms-and-privacy-policy"],
    name: "terms-and-privacy-policy___fr-ma"
  }, {
    path: "/fr-ma/users",
    component: _0ff02178,
    meta: {},
    alias: ["/amp/fr-ma/users"],
    name: "users___fr-ma"
  }, {
    path: "/ar-ae/account/choose-username",
    component: _5c178f52,
    meta: {},
    alias: ["/amp/ar-ae/account/choose-username"],
    name: "account-choose-username___ar-ae"
  }, {
    path: "/ar-ae/account/email-confirmation",
    component: _2566cd8b,
    meta: {},
    alias: ["/amp/ar-ae/account/email-confirmation"],
    name: "account-email-confirmation___ar-ae"
  }, {
    path: "/ar-ae/account/login",
    component: _a0095598,
    meta: {},
    alias: ["/amp/ar-ae/account/login"],
    name: "account-login___ar-ae"
  }, {
    path: "/ar-ae/account/reset-password",
    component: _411a711e,
    meta: {},
    alias: ["/amp/ar-ae/account/reset-password"],
    name: "account-reset-password___ar-ae"
  }, {
    path: "/ar-ae/account/signup",
    component: _c86d8346,
    meta: {},
    alias: ["/amp/ar-ae/account/signup"],
    name: "account-signup___ar-ae"
  }, {
    path: "/ar-ae/admin/experts",
    component: _2d3e5814,
    meta: {},
    alias: ["/amp/ar-ae/admin/experts"],
    name: "admin-experts___ar-ae"
  }, {
    path: "/ar-ae/e-services/cases",
    component: _eabad5c6,
    meta: {},
    alias: ["/amp/ar-ae/e-services/cases"],
    name: "e-services-cases___ar-ae"
  }, {
    path: "/ar-ae/e-services/rent",
    component: _72d3584f,
    meta: {},
    alias: ["/amp/ar-ae/e-services/rent"],
    name: "e-services-rent___ar-ae"
  }, {
    path: "/ar-ae/e-services/rent-appartment",
    component: _154006ee,
    meta: {},
    alias: ["/amp/ar-ae/e-services/rent-appartment"],
    name: "e-services-rent-appartment___ar-ae"
  }, {
    path: "/ar-ae/experts/signup",
    component: _279d3f81,
    meta: {},
    alias: ["/amp/ar-ae/experts/signup"],
    name: "experts-signup___ar-ae"
  }, {
    path: "/ar-ae/pages/free-consultations",
    component: _ffc9fc12,
    meta: {},
    alias: ["/amp/ar-ae/pages/free-consultations"],
    name: "pages-free-consultations___ar-ae"
  }, {
    path: "/ar-ae/pages/good-conduct",
    component: _5c8e5bc2,
    meta: {},
    alias: ["/amp/ar-ae/pages/good-conduct"],
    name: "pages-good-conduct___ar-ae"
  }, {
    path: "/ar-ae/pages/housing-certificate",
    component: _70580f09,
    meta: {},
    alias: ["/amp/ar-ae/pages/housing-certificate"],
    name: "pages-housing-certificate___ar-ae"
  }, {
    path: "/ar-eg/account/choose-username",
    component: _5c178f52,
    meta: {},
    alias: ["/amp/ar-eg/account/choose-username"],
    name: "account-choose-username___ar-eg"
  }, {
    path: "/ar-eg/account/email-confirmation",
    component: _2566cd8b,
    meta: {},
    alias: ["/amp/ar-eg/account/email-confirmation"],
    name: "account-email-confirmation___ar-eg"
  }, {
    path: "/ar-eg/account/login",
    component: _a0095598,
    meta: {},
    alias: ["/amp/ar-eg/account/login"],
    name: "account-login___ar-eg"
  }, {
    path: "/ar-eg/account/reset-password",
    component: _411a711e,
    meta: {},
    alias: ["/amp/ar-eg/account/reset-password"],
    name: "account-reset-password___ar-eg"
  }, {
    path: "/ar-eg/account/signup",
    component: _c86d8346,
    meta: {},
    alias: ["/amp/ar-eg/account/signup"],
    name: "account-signup___ar-eg"
  }, {
    path: "/ar-eg/admin/experts",
    component: _2d3e5814,
    meta: {},
    alias: ["/amp/ar-eg/admin/experts"],
    name: "admin-experts___ar-eg"
  }, {
    path: "/ar-eg/e-services/cases",
    component: _eabad5c6,
    meta: {},
    alias: ["/amp/ar-eg/e-services/cases"],
    name: "e-services-cases___ar-eg"
  }, {
    path: "/ar-eg/e-services/rent",
    component: _72d3584f,
    meta: {},
    alias: ["/amp/ar-eg/e-services/rent"],
    name: "e-services-rent___ar-eg"
  }, {
    path: "/ar-eg/e-services/rent-appartment",
    component: _154006ee,
    meta: {},
    alias: ["/amp/ar-eg/e-services/rent-appartment"],
    name: "e-services-rent-appartment___ar-eg"
  }, {
    path: "/ar-eg/experts/signup",
    component: _279d3f81,
    meta: {},
    alias: ["/amp/ar-eg/experts/signup"],
    name: "experts-signup___ar-eg"
  }, {
    path: "/ar-eg/pages/free-consultations",
    component: _ffc9fc12,
    meta: {},
    alias: ["/amp/ar-eg/pages/free-consultations"],
    name: "pages-free-consultations___ar-eg"
  }, {
    path: "/ar-eg/pages/good-conduct",
    component: _5c8e5bc2,
    meta: {},
    alias: ["/amp/ar-eg/pages/good-conduct"],
    name: "pages-good-conduct___ar-eg"
  }, {
    path: "/ar-eg/pages/housing-certificate",
    component: _70580f09,
    meta: {},
    alias: ["/amp/ar-eg/pages/housing-certificate"],
    name: "pages-housing-certificate___ar-eg"
  }, {
    path: "/ar-ma/account/choose-username",
    component: _5c178f52,
    meta: {},
    alias: ["/amp/ar-ma/account/choose-username"],
    name: "account-choose-username___ar-ma"
  }, {
    path: "/ar-ma/account/email-confirmation",
    component: _2566cd8b,
    meta: {},
    alias: ["/amp/ar-ma/account/email-confirmation"],
    name: "account-email-confirmation___ar-ma"
  }, {
    path: "/ar-ma/account/login",
    component: _a0095598,
    meta: {},
    alias: ["/amp/ar-ma/account/login"],
    name: "account-login___ar-ma"
  }, {
    path: "/ar-ma/account/reset-password",
    component: _411a711e,
    meta: {},
    alias: ["/amp/ar-ma/account/reset-password"],
    name: "account-reset-password___ar-ma"
  }, {
    path: "/ar-ma/account/signup",
    component: _c86d8346,
    meta: {},
    alias: ["/amp/ar-ma/account/signup"],
    name: "account-signup___ar-ma"
  }, {
    path: "/ar-ma/admin/experts",
    component: _2d3e5814,
    meta: {},
    alias: ["/amp/ar-ma/admin/experts"],
    name: "admin-experts___ar-ma"
  }, {
    path: "/ar-ma/e-services/cases",
    component: _eabad5c6,
    meta: {},
    alias: ["/amp/ar-ma/e-services/cases"],
    name: "e-services-cases___ar-ma"
  }, {
    path: "/ar-ma/e-services/rent",
    component: _72d3584f,
    meta: {},
    alias: ["/amp/ar-ma/e-services/rent"],
    name: "e-services-rent___ar-ma"
  }, {
    path: "/ar-ma/e-services/rent-appartment",
    component: _154006ee,
    meta: {},
    alias: ["/amp/ar-ma/e-services/rent-appartment"],
    name: "e-services-rent-appartment___ar-ma"
  }, {
    path: "/ar-ma/experts/signup",
    component: _279d3f81,
    meta: {},
    alias: ["/amp/ar-ma/experts/signup"],
    name: "experts-signup___ar-ma"
  }, {
    path: "/ar-ma/pages/free-consultations",
    component: _ffc9fc12,
    meta: {},
    alias: ["/amp/ar-ma/pages/free-consultations"],
    name: "pages-free-consultations___ar-ma"
  }, {
    path: "/ar-ma/pages/good-conduct",
    component: _5c8e5bc2,
    meta: {},
    alias: ["/amp/ar-ma/pages/good-conduct"],
    name: "pages-good-conduct___ar-ma"
  }, {
    path: "/ar-ma/pages/housing-certificate",
    component: _70580f09,
    meta: {},
    alias: ["/amp/ar-ma/pages/housing-certificate"],
    name: "pages-housing-certificate___ar-ma"
  }, {
    path: "/ar-sa/account/choose-username",
    component: _5c178f52,
    meta: {},
    alias: ["/amp/ar-sa/account/choose-username"],
    name: "account-choose-username___ar-sa"
  }, {
    path: "/ar-sa/account/email-confirmation",
    component: _2566cd8b,
    meta: {},
    alias: ["/amp/ar-sa/account/email-confirmation"],
    name: "account-email-confirmation___ar-sa"
  }, {
    path: "/ar-sa/account/login",
    component: _a0095598,
    meta: {},
    alias: ["/amp/ar-sa/account/login"],
    name: "account-login___ar-sa"
  }, {
    path: "/ar-sa/account/reset-password",
    component: _411a711e,
    meta: {},
    alias: ["/amp/ar-sa/account/reset-password"],
    name: "account-reset-password___ar-sa"
  }, {
    path: "/ar-sa/account/signup",
    component: _c86d8346,
    meta: {},
    alias: ["/amp/ar-sa/account/signup"],
    name: "account-signup___ar-sa"
  }, {
    path: "/ar-sa/admin/experts",
    component: _2d3e5814,
    meta: {},
    alias: ["/amp/ar-sa/admin/experts"],
    name: "admin-experts___ar-sa"
  }, {
    path: "/ar-sa/e-services/cases",
    component: _eabad5c6,
    meta: {},
    alias: ["/amp/ar-sa/e-services/cases"],
    name: "e-services-cases___ar-sa"
  }, {
    path: "/ar-sa/e-services/rent",
    component: _72d3584f,
    meta: {},
    alias: ["/amp/ar-sa/e-services/rent"],
    name: "e-services-rent___ar-sa"
  }, {
    path: "/ar-sa/e-services/rent-appartment",
    component: _154006ee,
    meta: {},
    alias: ["/amp/ar-sa/e-services/rent-appartment"],
    name: "e-services-rent-appartment___ar-sa"
  }, {
    path: "/ar-sa/experts/signup",
    component: _279d3f81,
    meta: {},
    alias: ["/amp/ar-sa/experts/signup"],
    name: "experts-signup___ar-sa"
  }, {
    path: "/ar-sa/pages/free-consultations",
    component: _ffc9fc12,
    meta: {},
    alias: ["/amp/ar-sa/pages/free-consultations"],
    name: "pages-free-consultations___ar-sa"
  }, {
    path: "/ar-sa/pages/good-conduct",
    component: _5c8e5bc2,
    meta: {},
    alias: ["/amp/ar-sa/pages/good-conduct"],
    name: "pages-good-conduct___ar-sa"
  }, {
    path: "/ar-sa/pages/housing-certificate",
    component: _70580f09,
    meta: {},
    alias: ["/amp/ar-sa/pages/housing-certificate"],
    name: "pages-housing-certificate___ar-sa"
  }, {
    path: "/en-ae/account/choose-username",
    component: _5c178f52,
    meta: {},
    alias: ["/amp/en-ae/account/choose-username"],
    name: "account-choose-username___en-ae"
  }, {
    path: "/en-ae/account/email-confirmation",
    component: _2566cd8b,
    meta: {},
    alias: ["/amp/en-ae/account/email-confirmation"],
    name: "account-email-confirmation___en-ae"
  }, {
    path: "/en-ae/account/login",
    component: _a0095598,
    meta: {},
    alias: ["/amp/en-ae/account/login"],
    name: "account-login___en-ae"
  }, {
    path: "/en-ae/account/reset-password",
    component: _411a711e,
    meta: {},
    alias: ["/amp/en-ae/account/reset-password"],
    name: "account-reset-password___en-ae"
  }, {
    path: "/en-ae/account/signup",
    component: _c86d8346,
    meta: {},
    alias: ["/amp/en-ae/account/signup"],
    name: "account-signup___en-ae"
  }, {
    path: "/en-ae/admin/experts",
    component: _2d3e5814,
    meta: {},
    alias: ["/amp/en-ae/admin/experts"],
    name: "admin-experts___en-ae"
  }, {
    path: "/en-ae/e-services/cases",
    component: _eabad5c6,
    meta: {},
    alias: ["/amp/en-ae/e-services/cases"],
    name: "e-services-cases___en-ae"
  }, {
    path: "/en-ae/e-services/rent",
    component: _72d3584f,
    meta: {},
    alias: ["/amp/en-ae/e-services/rent"],
    name: "e-services-rent___en-ae"
  }, {
    path: "/en-ae/e-services/rent-appartment",
    component: _154006ee,
    meta: {},
    alias: ["/amp/en-ae/e-services/rent-appartment"],
    name: "e-services-rent-appartment___en-ae"
  }, {
    path: "/en-ae/experts/signup",
    component: _279d3f81,
    meta: {},
    alias: ["/amp/en-ae/experts/signup"],
    name: "experts-signup___en-ae"
  }, {
    path: "/en-ae/pages/free-consultations",
    component: _ffc9fc12,
    meta: {},
    alias: ["/amp/en-ae/pages/free-consultations"],
    name: "pages-free-consultations___en-ae"
  }, {
    path: "/en-ae/pages/good-conduct",
    component: _5c8e5bc2,
    meta: {},
    alias: ["/amp/en-ae/pages/good-conduct"],
    name: "pages-good-conduct___en-ae"
  }, {
    path: "/en-ae/pages/housing-certificate",
    component: _70580f09,
    meta: {},
    alias: ["/amp/en-ae/pages/housing-certificate"],
    name: "pages-housing-certificate___en-ae"
  }, {
    path: "/en-eg/account/choose-username",
    component: _5c178f52,
    meta: {},
    alias: ["/amp/en-eg/account/choose-username"],
    name: "account-choose-username___en-eg"
  }, {
    path: "/en-eg/account/email-confirmation",
    component: _2566cd8b,
    meta: {},
    alias: ["/amp/en-eg/account/email-confirmation"],
    name: "account-email-confirmation___en-eg"
  }, {
    path: "/en-eg/account/login",
    component: _a0095598,
    meta: {},
    alias: ["/amp/en-eg/account/login"],
    name: "account-login___en-eg"
  }, {
    path: "/en-eg/account/reset-password",
    component: _411a711e,
    meta: {},
    alias: ["/amp/en-eg/account/reset-password"],
    name: "account-reset-password___en-eg"
  }, {
    path: "/en-eg/account/signup",
    component: _c86d8346,
    meta: {},
    alias: ["/amp/en-eg/account/signup"],
    name: "account-signup___en-eg"
  }, {
    path: "/en-eg/admin/experts",
    component: _2d3e5814,
    meta: {},
    alias: ["/amp/en-eg/admin/experts"],
    name: "admin-experts___en-eg"
  }, {
    path: "/en-eg/e-services/cases",
    component: _eabad5c6,
    meta: {},
    alias: ["/amp/en-eg/e-services/cases"],
    name: "e-services-cases___en-eg"
  }, {
    path: "/en-eg/e-services/rent",
    component: _72d3584f,
    meta: {},
    alias: ["/amp/en-eg/e-services/rent"],
    name: "e-services-rent___en-eg"
  }, {
    path: "/en-eg/e-services/rent-appartment",
    component: _154006ee,
    meta: {},
    alias: ["/amp/en-eg/e-services/rent-appartment"],
    name: "e-services-rent-appartment___en-eg"
  }, {
    path: "/en-eg/experts/signup",
    component: _279d3f81,
    meta: {},
    alias: ["/amp/en-eg/experts/signup"],
    name: "experts-signup___en-eg"
  }, {
    path: "/en-eg/pages/free-consultations",
    component: _ffc9fc12,
    meta: {},
    alias: ["/amp/en-eg/pages/free-consultations"],
    name: "pages-free-consultations___en-eg"
  }, {
    path: "/en-eg/pages/good-conduct",
    component: _5c8e5bc2,
    meta: {},
    alias: ["/amp/en-eg/pages/good-conduct"],
    name: "pages-good-conduct___en-eg"
  }, {
    path: "/en-eg/pages/housing-certificate",
    component: _70580f09,
    meta: {},
    alias: ["/amp/en-eg/pages/housing-certificate"],
    name: "pages-housing-certificate___en-eg"
  }, {
    path: "/en-sa/account/choose-username",
    component: _5c178f52,
    meta: {},
    alias: ["/amp/en-sa/account/choose-username"],
    name: "account-choose-username___en-sa"
  }, {
    path: "/en-sa/account/email-confirmation",
    component: _2566cd8b,
    meta: {},
    alias: ["/amp/en-sa/account/email-confirmation"],
    name: "account-email-confirmation___en-sa"
  }, {
    path: "/en-sa/account/login",
    component: _a0095598,
    meta: {},
    alias: ["/amp/en-sa/account/login"],
    name: "account-login___en-sa"
  }, {
    path: "/en-sa/account/reset-password",
    component: _411a711e,
    meta: {},
    alias: ["/amp/en-sa/account/reset-password"],
    name: "account-reset-password___en-sa"
  }, {
    path: "/en-sa/account/signup",
    component: _c86d8346,
    meta: {},
    alias: ["/amp/en-sa/account/signup"],
    name: "account-signup___en-sa"
  }, {
    path: "/en-sa/admin/experts",
    component: _2d3e5814,
    meta: {},
    alias: ["/amp/en-sa/admin/experts"],
    name: "admin-experts___en-sa"
  }, {
    path: "/en-sa/e-services/cases",
    component: _eabad5c6,
    meta: {},
    alias: ["/amp/en-sa/e-services/cases"],
    name: "e-services-cases___en-sa"
  }, {
    path: "/en-sa/e-services/rent",
    component: _72d3584f,
    meta: {},
    alias: ["/amp/en-sa/e-services/rent"],
    name: "e-services-rent___en-sa"
  }, {
    path: "/en-sa/e-services/rent-appartment",
    component: _154006ee,
    meta: {},
    alias: ["/amp/en-sa/e-services/rent-appartment"],
    name: "e-services-rent-appartment___en-sa"
  }, {
    path: "/en-sa/experts/signup",
    component: _279d3f81,
    meta: {},
    alias: ["/amp/en-sa/experts/signup"],
    name: "experts-signup___en-sa"
  }, {
    path: "/en-sa/pages/free-consultations",
    component: _ffc9fc12,
    meta: {},
    alias: ["/amp/en-sa/pages/free-consultations"],
    name: "pages-free-consultations___en-sa"
  }, {
    path: "/en-sa/pages/good-conduct",
    component: _5c8e5bc2,
    meta: {},
    alias: ["/amp/en-sa/pages/good-conduct"],
    name: "pages-good-conduct___en-sa"
  }, {
    path: "/en-sa/pages/housing-certificate",
    component: _70580f09,
    meta: {},
    alias: ["/amp/en-sa/pages/housing-certificate"],
    name: "pages-housing-certificate___en-sa"
  }, {
    path: "/fr-ma/account/choose-username",
    component: _5c178f52,
    meta: {},
    alias: ["/amp/fr-ma/account/choose-username"],
    name: "account-choose-username___fr-ma"
  }, {
    path: "/fr-ma/account/email-confirmation",
    component: _2566cd8b,
    meta: {},
    alias: ["/amp/fr-ma/account/email-confirmation"],
    name: "account-email-confirmation___fr-ma"
  }, {
    path: "/fr-ma/account/login",
    component: _a0095598,
    meta: {},
    alias: ["/amp/fr-ma/account/login"],
    name: "account-login___fr-ma"
  }, {
    path: "/fr-ma/account/reset-password",
    component: _411a711e,
    meta: {},
    alias: ["/amp/fr-ma/account/reset-password"],
    name: "account-reset-password___fr-ma"
  }, {
    path: "/fr-ma/account/signup",
    component: _c86d8346,
    meta: {},
    alias: ["/amp/fr-ma/account/signup"],
    name: "account-signup___fr-ma"
  }, {
    path: "/fr-ma/admin/experts",
    component: _2d3e5814,
    meta: {},
    alias: ["/amp/fr-ma/admin/experts"],
    name: "admin-experts___fr-ma"
  }, {
    path: "/fr-ma/e-services/cases",
    component: _eabad5c6,
    meta: {},
    alias: ["/amp/fr-ma/e-services/cases"],
    name: "e-services-cases___fr-ma"
  }, {
    path: "/fr-ma/e-services/rent",
    component: _72d3584f,
    meta: {},
    alias: ["/amp/fr-ma/e-services/rent"],
    name: "e-services-rent___fr-ma"
  }, {
    path: "/fr-ma/e-services/rent-appartment",
    component: _154006ee,
    meta: {},
    alias: ["/amp/fr-ma/e-services/rent-appartment"],
    name: "e-services-rent-appartment___fr-ma"
  }, {
    path: "/fr-ma/experts/signup",
    component: _279d3f81,
    meta: {},
    alias: ["/amp/fr-ma/experts/signup"],
    name: "experts-signup___fr-ma"
  }, {
    path: "/fr-ma/pages/free-consultations",
    component: _ffc9fc12,
    meta: {},
    alias: ["/amp/fr-ma/pages/free-consultations"],
    name: "pages-free-consultations___fr-ma"
  }, {
    path: "/fr-ma/pages/good-conduct",
    component: _5c8e5bc2,
    meta: {},
    alias: ["/amp/fr-ma/pages/good-conduct"],
    name: "pages-good-conduct___fr-ma"
  }, {
    path: "/fr-ma/pages/housing-certificate",
    component: _70580f09,
    meta: {},
    alias: ["/amp/fr-ma/pages/housing-certificate"],
    name: "pages-housing-certificate___fr-ma"
  }, {
    path: "/ar-ae/users/redirect/user/:username",
    component: _219172a6,
    meta: {},
    alias: ["/amp/ar-ae/users/redirect/user/:username"],
    name: "users-redirect-user-username___ar-ae"
  }, {
    path: "/ar-eg/users/redirect/user/:username",
    component: _219172a6,
    meta: {},
    alias: ["/amp/ar-eg/users/redirect/user/:username"],
    name: "users-redirect-user-username___ar-eg"
  }, {
    path: "/ar-ma/users/redirect/user/:username",
    component: _219172a6,
    meta: {},
    alias: ["/amp/ar-ma/users/redirect/user/:username"],
    name: "users-redirect-user-username___ar-ma"
  }, {
    path: "/ar-sa/users/redirect/user/:username",
    component: _219172a6,
    meta: {},
    alias: ["/amp/ar-sa/users/redirect/user/:username"],
    name: "users-redirect-user-username___ar-sa"
  }, {
    path: "/en-ae/users/redirect/user/:username",
    component: _219172a6,
    meta: {},
    alias: ["/amp/en-ae/users/redirect/user/:username"],
    name: "users-redirect-user-username___en-ae"
  }, {
    path: "/en-eg/users/redirect/user/:username",
    component: _219172a6,
    meta: {},
    alias: ["/amp/en-eg/users/redirect/user/:username"],
    name: "users-redirect-user-username___en-eg"
  }, {
    path: "/en-sa/users/redirect/user/:username",
    component: _219172a6,
    meta: {},
    alias: ["/amp/en-sa/users/redirect/user/:username"],
    name: "users-redirect-user-username___en-sa"
  }, {
    path: "/fr-ma/users/redirect/user/:username",
    component: _219172a6,
    meta: {},
    alias: ["/amp/fr-ma/users/redirect/user/:username"],
    name: "users-redirect-user-username___fr-ma"
  }, {
    path: "/ar-ae/users/redirect/user/:username?/:section",
    component: _24bea744,
    meta: {},
    alias: ["/amp/ar-ae/users/redirect/user/:username?/:section"],
    name: "users-redirect-user-username-section___ar-ae"
  }, {
    path: "/ar-eg/users/redirect/user/:username?/:section",
    component: _24bea744,
    meta: {},
    alias: ["/amp/ar-eg/users/redirect/user/:username?/:section"],
    name: "users-redirect-user-username-section___ar-eg"
  }, {
    path: "/ar-ma/users/redirect/user/:username?/:section",
    component: _24bea744,
    meta: {},
    alias: ["/amp/ar-ma/users/redirect/user/:username?/:section"],
    name: "users-redirect-user-username-section___ar-ma"
  }, {
    path: "/ar-sa/users/redirect/user/:username?/:section",
    component: _24bea744,
    meta: {},
    alias: ["/amp/ar-sa/users/redirect/user/:username?/:section"],
    name: "users-redirect-user-username-section___ar-sa"
  }, {
    path: "/en-ae/users/redirect/user/:username?/:section",
    component: _24bea744,
    meta: {},
    alias: ["/amp/en-ae/users/redirect/user/:username?/:section"],
    name: "users-redirect-user-username-section___en-ae"
  }, {
    path: "/en-eg/users/redirect/user/:username?/:section",
    component: _24bea744,
    meta: {},
    alias: ["/amp/en-eg/users/redirect/user/:username?/:section"],
    name: "users-redirect-user-username-section___en-eg"
  }, {
    path: "/en-sa/users/redirect/user/:username?/:section",
    component: _24bea744,
    meta: {},
    alias: ["/amp/en-sa/users/redirect/user/:username?/:section"],
    name: "users-redirect-user-username-section___en-sa"
  }, {
    path: "/fr-ma/users/redirect/user/:username?/:section",
    component: _24bea744,
    meta: {},
    alias: ["/amp/fr-ma/users/redirect/user/:username?/:section"],
    name: "users-redirect-user-username-section___fr-ma"
  }, {
    path: "/ar-ae/consultations/filter/:type?",
    component: _4d01a46c,
    meta: {},
    alias: ["/amp/ar-ae/consultations/filter/:type?"],
    name: "consultations-filter-type___ar-ae"
  }, {
    path: "/ar-eg/consultations/filter/:type?",
    component: _4d01a46c,
    meta: {},
    alias: ["/amp/ar-eg/consultations/filter/:type?"],
    name: "consultations-filter-type___ar-eg"
  }, {
    path: "/ar-ma/consultations/filter/:type?",
    component: _4d01a46c,
    meta: {},
    alias: ["/amp/ar-ma/consultations/filter/:type?"],
    name: "consultations-filter-type___ar-ma"
  }, {
    path: "/ar-sa/consultations/filter/:type?",
    component: _4d01a46c,
    meta: {},
    alias: ["/amp/ar-sa/consultations/filter/:type?"],
    name: "consultations-filter-type___ar-sa"
  }, {
    path: "/en-ae/consultations/filter/:type?",
    component: _4d01a46c,
    meta: {},
    alias: ["/amp/en-ae/consultations/filter/:type?"],
    name: "consultations-filter-type___en-ae"
  }, {
    path: "/en-eg/consultations/filter/:type?",
    component: _4d01a46c,
    meta: {},
    alias: ["/amp/en-eg/consultations/filter/:type?"],
    name: "consultations-filter-type___en-eg"
  }, {
    path: "/en-sa/consultations/filter/:type?",
    component: _4d01a46c,
    meta: {},
    alias: ["/amp/en-sa/consultations/filter/:type?"],
    name: "consultations-filter-type___en-sa"
  }, {
    path: "/fr-ma/consultations/filter/:type?",
    component: _4d01a46c,
    meta: {},
    alias: ["/amp/fr-ma/consultations/filter/:type?"],
    name: "consultations-filter-type___fr-ma"
  }, {
    path: "/ar-ae/invite/:id",
    component: _397a82cc,
    meta: {},
    alias: ["/amp/ar-ae/invite/:id"],
    name: "invite-id___ar-ae"
  }, {
    path: "/ar-ae/pay/:reference",
    component: _75aa338e,
    meta: {},
    alias: ["/amp/ar-ae/pay/:reference"],
    name: "pay-reference___ar-ae"
  }, {
    path: "/ar-eg/invite/:id",
    component: _397a82cc,
    meta: {},
    alias: ["/amp/ar-eg/invite/:id"],
    name: "invite-id___ar-eg"
  }, {
    path: "/ar-eg/pay/:reference",
    component: _75aa338e,
    meta: {},
    alias: ["/amp/ar-eg/pay/:reference"],
    name: "pay-reference___ar-eg"
  }, {
    path: "/ar-ma/invite/:id",
    component: _397a82cc,
    meta: {},
    alias: ["/amp/ar-ma/invite/:id"],
    name: "invite-id___ar-ma"
  }, {
    path: "/ar-ma/pay/:reference",
    component: _75aa338e,
    meta: {},
    alias: ["/amp/ar-ma/pay/:reference"],
    name: "pay-reference___ar-ma"
  }, {
    path: "/ar-sa/invite/:id",
    component: _397a82cc,
    meta: {},
    alias: ["/amp/ar-sa/invite/:id"],
    name: "invite-id___ar-sa"
  }, {
    path: "/ar-sa/pay/:reference",
    component: _75aa338e,
    meta: {},
    alias: ["/amp/ar-sa/pay/:reference"],
    name: "pay-reference___ar-sa"
  }, {
    path: "/en-ae/invite/:id",
    component: _397a82cc,
    meta: {},
    alias: ["/amp/en-ae/invite/:id"],
    name: "invite-id___en-ae"
  }, {
    path: "/en-ae/pay/:reference",
    component: _75aa338e,
    meta: {},
    alias: ["/amp/en-ae/pay/:reference"],
    name: "pay-reference___en-ae"
  }, {
    path: "/en-eg/invite/:id",
    component: _397a82cc,
    meta: {},
    alias: ["/amp/en-eg/invite/:id"],
    name: "invite-id___en-eg"
  }, {
    path: "/en-eg/pay/:reference",
    component: _75aa338e,
    meta: {},
    alias: ["/amp/en-eg/pay/:reference"],
    name: "pay-reference___en-eg"
  }, {
    path: "/en-sa/invite/:id",
    component: _397a82cc,
    meta: {},
    alias: ["/amp/en-sa/invite/:id"],
    name: "invite-id___en-sa"
  }, {
    path: "/en-sa/pay/:reference",
    component: _75aa338e,
    meta: {},
    alias: ["/amp/en-sa/pay/:reference"],
    name: "pay-reference___en-sa"
  }, {
    path: "/fr-ma/invite/:id",
    component: _397a82cc,
    meta: {},
    alias: ["/amp/fr-ma/invite/:id"],
    name: "invite-id___fr-ma"
  }, {
    path: "/fr-ma/pay/:reference",
    component: _75aa338e,
    meta: {},
    alias: ["/amp/fr-ma/pay/:reference"],
    name: "pay-reference___fr-ma"
  }, {
    path: "/ar-ae/cases/:id?/:slug?",
    component: _dd4b8dd4,
    meta: {},
    alias: ["/amp/ar-ae/cases/:id?/:slug?"],
    name: "cases-id-slug___ar-ae"
  }, {
    path: "/ar-ae/consultations/:id?/:slug?",
    component: _62507f9d,
    meta: {},
    alias: ["/amp/ar-ae/consultations/:id?/:slug?"],
    name: "consultations-id-slug___ar-ae"
  }, {
    path: "/ar-ae/experts/:id?/:username?",
    component: _d65ae472,
    meta: {},
    alias: ["/amp/ar-ae/experts/:id?/:username?"],
    name: "experts-id-username___ar-ae"
  }, {
    path: "/ar-ae/users/:id/:username?",
    component: _086043c6,
    meta: {},
    alias: ["/amp/ar-ae/users/:id/:username?"],
    name: "users-id-username___ar-ae"
  }, {
    path: "/ar-eg/cases/:id?/:slug?",
    component: _dd4b8dd4,
    meta: {},
    alias: ["/amp/ar-eg/cases/:id?/:slug?"],
    name: "cases-id-slug___ar-eg"
  }, {
    path: "/ar-eg/consultations/:id?/:slug?",
    component: _62507f9d,
    meta: {},
    alias: ["/amp/ar-eg/consultations/:id?/:slug?"],
    name: "consultations-id-slug___ar-eg"
  }, {
    path: "/ar-eg/experts/:id?/:username?",
    component: _d65ae472,
    meta: {},
    alias: ["/amp/ar-eg/experts/:id?/:username?"],
    name: "experts-id-username___ar-eg"
  }, {
    path: "/ar-eg/users/:id/:username?",
    component: _086043c6,
    meta: {},
    alias: ["/amp/ar-eg/users/:id/:username?"],
    name: "users-id-username___ar-eg"
  }, {
    path: "/ar-ma/cases/:id?/:slug?",
    component: _dd4b8dd4,
    meta: {},
    alias: ["/amp/ar-ma/cases/:id?/:slug?"],
    name: "cases-id-slug___ar-ma"
  }, {
    path: "/ar-ma/consultations/:id?/:slug?",
    component: _62507f9d,
    meta: {},
    alias: ["/amp/ar-ma/consultations/:id?/:slug?"],
    name: "consultations-id-slug___ar-ma"
  }, {
    path: "/ar-ma/experts/:id?/:username?",
    component: _d65ae472,
    meta: {},
    alias: ["/amp/ar-ma/experts/:id?/:username?"],
    name: "experts-id-username___ar-ma"
  }, {
    path: "/ar-ma/users/:id/:username?",
    component: _086043c6,
    meta: {},
    alias: ["/amp/ar-ma/users/:id/:username?"],
    name: "users-id-username___ar-ma"
  }, {
    path: "/ar-sa/cases/:id?/:slug?",
    component: _dd4b8dd4,
    meta: {},
    alias: ["/amp/ar-sa/cases/:id?/:slug?"],
    name: "cases-id-slug___ar-sa"
  }, {
    path: "/ar-sa/consultations/:id?/:slug?",
    component: _62507f9d,
    meta: {},
    alias: ["/amp/ar-sa/consultations/:id?/:slug?"],
    name: "consultations-id-slug___ar-sa"
  }, {
    path: "/ar-sa/experts/:id?/:username?",
    component: _d65ae472,
    meta: {},
    alias: ["/amp/ar-sa/experts/:id?/:username?"],
    name: "experts-id-username___ar-sa"
  }, {
    path: "/ar-sa/users/:id/:username?",
    component: _086043c6,
    meta: {},
    alias: ["/amp/ar-sa/users/:id/:username?"],
    name: "users-id-username___ar-sa"
  }, {
    path: "/en-ae/cases/:id?/:slug?",
    component: _dd4b8dd4,
    meta: {},
    alias: ["/amp/en-ae/cases/:id?/:slug?"],
    name: "cases-id-slug___en-ae"
  }, {
    path: "/en-ae/consultations/:id?/:slug?",
    component: _62507f9d,
    meta: {},
    alias: ["/amp/en-ae/consultations/:id?/:slug?"],
    name: "consultations-id-slug___en-ae"
  }, {
    path: "/en-ae/experts/:id?/:username?",
    component: _d65ae472,
    meta: {},
    alias: ["/amp/en-ae/experts/:id?/:username?"],
    name: "experts-id-username___en-ae"
  }, {
    path: "/en-ae/users/:id/:username?",
    component: _086043c6,
    meta: {},
    alias: ["/amp/en-ae/users/:id/:username?"],
    name: "users-id-username___en-ae"
  }, {
    path: "/en-eg/cases/:id?/:slug?",
    component: _dd4b8dd4,
    meta: {},
    alias: ["/amp/en-eg/cases/:id?/:slug?"],
    name: "cases-id-slug___en-eg"
  }, {
    path: "/en-eg/consultations/:id?/:slug?",
    component: _62507f9d,
    meta: {},
    alias: ["/amp/en-eg/consultations/:id?/:slug?"],
    name: "consultations-id-slug___en-eg"
  }, {
    path: "/en-eg/experts/:id?/:username?",
    component: _d65ae472,
    meta: {},
    alias: ["/amp/en-eg/experts/:id?/:username?"],
    name: "experts-id-username___en-eg"
  }, {
    path: "/en-eg/users/:id/:username?",
    component: _086043c6,
    meta: {},
    alias: ["/amp/en-eg/users/:id/:username?"],
    name: "users-id-username___en-eg"
  }, {
    path: "/en-sa/cases/:id?/:slug?",
    component: _dd4b8dd4,
    meta: {},
    alias: ["/amp/en-sa/cases/:id?/:slug?"],
    name: "cases-id-slug___en-sa"
  }, {
    path: "/en-sa/consultations/:id?/:slug?",
    component: _62507f9d,
    meta: {},
    alias: ["/amp/en-sa/consultations/:id?/:slug?"],
    name: "consultations-id-slug___en-sa"
  }, {
    path: "/en-sa/experts/:id?/:username?",
    component: _d65ae472,
    meta: {},
    alias: ["/amp/en-sa/experts/:id?/:username?"],
    name: "experts-id-username___en-sa"
  }, {
    path: "/en-sa/users/:id/:username?",
    component: _086043c6,
    meta: {},
    alias: ["/amp/en-sa/users/:id/:username?"],
    name: "users-id-username___en-sa"
  }, {
    path: "/fr-ma/cases/:id?/:slug?",
    component: _dd4b8dd4,
    meta: {},
    alias: ["/amp/fr-ma/cases/:id?/:slug?"],
    name: "cases-id-slug___fr-ma"
  }, {
    path: "/fr-ma/consultations/:id?/:slug?",
    component: _62507f9d,
    meta: {},
    alias: ["/amp/fr-ma/consultations/:id?/:slug?"],
    name: "consultations-id-slug___fr-ma"
  }, {
    path: "/fr-ma/experts/:id?/:username?",
    component: _d65ae472,
    meta: {},
    alias: ["/amp/fr-ma/experts/:id?/:username?"],
    name: "experts-id-username___fr-ma"
  }, {
    path: "/fr-ma/users/:id/:username?",
    component: _086043c6,
    meta: {},
    alias: ["/amp/fr-ma/users/:id/:username?"],
    name: "users-id-username___fr-ma"
  }, {
    path: "/tag/:keyword",
    component: _a94d3a3c,
    meta: {},
    alias: ["/amp/tag/:keyword"],
    name: "tag-keyword"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
