<template>
  <v-alert
    :dismissible="dismissible"
    :type="type"
    :align="align"
    :prominent="prominent"
    :dense="dense"
    :icon="icon"
  >
    <p v-if="title !== ''">
      <strong>
        {{ title }}
      </strong>
    </p>
    <span v-html="message" />
    <nuxt-link v-if="link" class="ms-2 white--text" :to="link">
      {{ linkTitle }}
    </nuxt-link>
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    prominent: {
      type: Boolean,
      required: false,
      default: false
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    message: {
      type: String,
      required: true
    },
    align: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: undefined
    },
    link: {
      type: String,
      required: false,
      default: undefined
    },
    linkTitle: {
      type: String,
      required: false,
      default: undefined
    }
  }
}
</script>
<style lang="scss">
.v-alert {
  border-radius: 0 !important;
  &.warning {
    color: #502121 !important;
    a {
      color: #502121;
      font-weight: bold;
      &:hover {
        color: #502121;
      }
    }
  }
}
</style>
