<template>
  <v-app>
    <Header />
    <v-main>
      <v-container>
        <nuxt />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/PayComponents/Header'
export default {
  components: {
    Header
  },
  data () {
    return {
      lang: this.$i18n.locale.substring(0, 2),
      market: this.$i18n.locale.substring(3, 5),
      i18nSeoFilteredLinks: [],
      i18nSeoFilteredMeta: []
    }
  },
  head () {
    this.i18nSeoFilteredLinks = []
    this.i18nSeoFilteredMeta = []
    const i18nSeo = this.$nuxtI18nSeo()
    const restrictToCurrentMarket =
      (this.$route.name && this.$route.name.includes('consultations-id-slug')) ||
      (this.$route.name && this.$route.name.includes('e-services-cases')) ||
      (this.$route.name && this.$route.name.includes('users-id-username')) ||
      (this.$route.name && this.$route.name.includes('experts-id-username'))
    if (!this.$isAMP) {
      if (i18nSeo && i18nSeo.link) {
        i18nSeo.link.forEach((link) => {
          if (link && link.href) {
            const linkMarket = link.href.substring(4, 6)
            const marketDefaultLang = this.$t('markets').filter(i => i.country === linkMarket)[0].default_language
            if (!link.hreflang || ((link.hreflang && link.hreflang.length > 2) && (linkMarket === this.market || (linkMarket !== this.market && !restrictToCurrentMarket && link.hreflang.substring(0, 2) === marketDefaultLang)))) {
              link.href = `${process.env.SITE_URL}${link.href}`
              this.i18nSeoFilteredLinks.push(link)
            }
          }
        })
      }
    }
    if (i18nSeo && i18nSeo.meta) {
      i18nSeo.meta.forEach((meta) => {
        if (meta && meta.content) {
          const metaMarket = meta.content.substring(3, 5)
          const marketDefaultLang = this.$t('markets').filter(i => i.country === metaMarket)[0].default_language
          if (metaMarket === this.market || (metaMarket !== this.market && !restrictToCurrentMarket && meta.content.substring(0, 2) === marketDefaultLang)) {
            this.i18nSeoFilteredMeta.push(meta)
          }
        }
      })
    }

    return {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs
      },
      link: [...this.i18nSeoFilteredLinks],
      meta: [...this.i18nSeoFilteredMeta]
    }
  },
  created () {
    if (this.$device.isMobileOrTablet) {
      this.$store.commit('setIsMobile', true)
    } else {
      this.$store.commit('setIsMobile', false)
    }
    if (!this.$i18n.locale) {
      this.$i18n.locale = 'ar-ma'
    }
    if (this.lang) {
      this.$vuetify.rtl = this.lang === 'ar'
    } else {
      this.$vuetify.rtl = true
    }
    this.$dayjs.locale(this.lang)
  },
  jsonld () {
    return {
      '@context': 'http://schema.org/',
      '@type': 'WebSite',
      name: this.$t('globals.siteName'),
      alternateName: 'Mahkamaty.com',
      url: 'https://mahkamaty.com',
      image: 'https://mahkamaty.com/img/logo-dark.svg',
      sameAs: [
        'https://www.facebook.com/mahkamatyofficial',
        'https://twitter.com/mahkamaty'
      ],
      potentialAction: {
        '@type': 'SearchAction',
        target: `https://mahkamaty.com/${this.$i18n.locale}/consultations?search={search_term_string}`,
        'query-input': 'required name=search_term_string'
      }
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Dubai";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Dubai"), local("Dubai-Regular"),
  url("/fonts/dubai-regular.woff2") format("woff2"),
  url("/fonts/dubai-regular.woff") format("woff");
}
@font-face {
  font-family: "Dubai";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Dubai Bold"), local("Dubai-Bold"),
  url("/fonts/dubai-bold.woff2") format("woff2"),
  url("/fonts/dubai-bold.woff") format("woff");
}
html {
  scroll-behavior: smooth;
  font-size: 18px!important;
}
body {
  overflow: hidden;
}
.ltr {
  direction: ltr;
}
.rtl {
  direction: rtl;
}
.v-application {
  padding: 0;
  margin: 0;
  .container {
    padding: 0;
  }
  a {
    color: $default-text-color;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      color: $mahkamaty-blue-color-alt;
      text-decoration: underline;
    }
  }
  &.theme--light {
    color: $default-text-color;
    background-color: $page-background-color !important;
    button {
      color: $white-color;
    }
  }
  .v-btn.primary {
    background: $mahkamaty-blue-color !important;
    &:hover {
      background: $mahkamaty-blue-color-alt !important;
    }
  }
  .v-btn {
    text-transform: unset;
    letter-spacing: unset;
  }
  .accent-icon {
    color: $default-accent-color !important;
  }
  &--is-rtl {
    .v-input__slot {
      text-align: right;
    }
    .v-radio {
      margin-right: 0;
    }
  }
}
.subtitle-1 {
  line-height: 1!important;
}
.v-slide-group__content {
  display: flex
}
.no-hover {
  text-decoration: none !important;
}
.noselect {
-webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>
