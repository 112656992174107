export default function ({ $axios }) {
    if (process.client) {
      $axios.onRequest(config => {
        const gaCookie = document.cookie.split('; ').find(row => row.startsWith('_ga='));
        if (gaCookie) {
          const gaValue = gaCookie.split('=')[1];
          config.headers.common['_ga'] = gaValue;
        }
        return config;
      });
    }
  }
  