import { getCountryNameFromCountryCode } from '@/utils'
import { logger } from '@/utils/logger'

export function trackEvent (eventName, eventProperties, user) {
  if (window && window.rudderanalytics) {
    if (user && user.email) {
      window.rudderanalytics.identify(user.email,
        {
          email: user.email,
          legal_country: getCountryNameFromCountryCode(user.country),
          ui_lang: user.language,
          rank: user.role,
          web_notifications_activated: user.firebase_token !== null
        },
        {},
        () => {
          logger('Identify call sent')
          window.rudderanalytics.track(eventName, eventProperties)
          logger('Tracking event sent: ' + eventName + ' ' + JSON.stringify(eventProperties))
        }
      )
    } else {
      window.rudderanalytics.track(eventName, eventProperties)
      logger('Tracking event sent: ' + eventName + ' ' + JSON.stringify(eventProperties))
    }
  }
}
