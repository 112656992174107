<template>
  <div>
    <div
      v-if="!clientLoaded"
      class="pa-3 text-lg-center"
    >
      <i class="v-icon icon-notifications" />
      <p class="ma-0 pa-0 hidden-md-and-down">
        <span class="link-title">{{ $t('notifications.title') }}</span>
      </p>
      <div v-show="menu" class="arrow-up" />
    </div>
    <v-menu
      v-model="menu"
      open-on-click
      :close-on-content-click="true"
      light
      :left="!isMobile && $vuetify.rtl"
      :nudge-bottom="isMobile ? 47 : 74"
      :max-width="isMobile ? '' : '400'"
      :min-width="isMobile ? '100%' : '400'"
    >
      <template #activator="{ on }">
        <div
          class="noselect hover pa-3 text-lg-center"
          :class="{ 'active': menu, 'radius': isMobile }"
          v-on="on"
        >
          <v-badge
            v-if="clientLoaded && showNotificationsDot"
            :content="numberOfUnseenNotifications"
            color="red"
            overlap
            align="center"
          >
            <i class="v-icon icon-notifications" />
          </v-badge>
          <i v-else class="v-icon icon-notifications" />
          <p class="ma-0 pa-0 hidden-md-and-down">
            <span class="link-title">{{ $t('notifications.title') }}</span>
          </p>
          <div v-show="menu" class="arrow-up" />
        </div>
      </template>
      <v-list
        dense
      >
        <Notifications
          source="menu"
          :set-last-visit="true"
          @opened="closeMnue"
        />
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Notifications from '@/components/Notifications'

export default {
  name: 'NotificationsMenu',
  components: {
    Notifications
  },
  data () {
    return {
      menu: false,
      clientLoaded: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    },
    showNotificationsDot () {
      return this.$store.state.showNotificationsDot
    },
    numberOfUnseenNotifications () {
      return this.$store.state.numberOfUnseenNotifications.toString()
    }
  },
  mounted () {
    this.clientLoaded = true
  },
  methods: {
    closeMnue () {
      this.menu = false
    }
  }
}
</script>
<style lang="scss" scoped>
.link-title {
  font-size: 85%!important;
}
.v-icon {
  color: $default-text-color;
  margin: 0 auto;
}
.hover {
  &:hover,
  &.active
   {
    cursor: pointer;
    position: relative;
    background: $page-background-color;
  }
}
.radius {
  border-radius: 50%;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $default-text-color;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
