<template>
  <div class="account-links" @click="goToAccount">
    <span class="d-block d-lg-inline-block pa-3 ma-0 text-lg-center">
      <i class="d-inline-block d-lg-block v-icon icon-account" />
      <span class="link-title">{{ $t('nav.myAccount') }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AccountGuest',
  methods: {
    goToAccount () {
      this.$router.push(
        this.localePath({
          name: 'account-login',
          query: { redirect_uri: this.$route.fullPath }
        })
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.link-title {
  font-size: 85% !important;
}
.v-icon {
  color: $default-text-color;
  margin: 0 auto;
}
.account-links {
  a {
    font-weight: normal !important;
    &:hover {
      background: $page-background-color;
      color: $default-text-color;
      text-decoration: none;
    }
  }
}
</style>
