export default function({app}) {
  return {
    icons: {
      values: {
        menu: 'icon-menu',
        success: 'icon-check-circle',
        info: 'icon-information',
        warning: 'icon-exclamation',
        error: 'icon-alert',
        checkboxOff: 'icon-checkbox-blank-outline',
        checkboxOn: 'icon-checkbox-marked',
        radioOff: 'icon-radiobox-blank',
        radioOn: 'icon-radiobox-marked',
        dropdown: 'icon-menu-down',
        prev: 'icon-chevron-left',
        next: 'icon-chevron-right',
        complete: 'icon-12 icon-check',
        edit: 'icon-12 icon-pencil',
        expand: 'icon-menu-down',
        close: 'icon-close',
        cancel: 'icon-close'
      }
    },
    theme: {
      themes: {
        light: {
          primary: '#0153B2',
          warning: '#FFC107',
          accent: '#1B77F2',
          success: '#00663A'
        },
        dark: {
          primary: '#0153B2',
          warning: '#E08300'
        }
      }
    }
  }
}
