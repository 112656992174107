export default ({ app, store }) => {
  if (!app.mixins) {
    app.mixins = []
  }
  app.mixins.push({
    mounted () {
      if (app.$device.isMobileOrTablet) {
        store.commit('setIsMobile', true)
      } else {
        store.commit('setIsMobile', false)
      }
    }
  })
}
