<template>
  <div class="container">
    <Alert
      :message="$t('errorMessages.cannotAccessPage')"
      type="error"
      class="mt-6"
    />
    <Search class="search mt-6" />
  </div>
</template>

<script>
import Alert from '@/components/Alert'
import Search from '@/components/Search'

export default {
  components: {
    Alert,
    Search
  },
  layout: 'simple'
}
</script>
<style lang="scss">
.v-application {
  .search {
    &.ms-lg-8 {
      margin-right: 0px!important;
      margin-left: 0px!important;
    }
  }
}
</style>
