<template>
  <v-snackbar
    v-model="model"
    :top="true"
    :timeout="timeout"
    :color="color"
  >
    <v-row
      no-gutters
    >
      <v-col
        cols="8"
        class="message"
        align="start"
      >
        {{ message }}
      </v-col>
      <v-col
        cols="2"
        align="end"
      >
        <v-btn
          text
          class="ma-0"
          @click="$store.commit('setSnackbar', false)"
        >
          {{ $t('globals.close') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    timeout: {
      type: Number,
      required: false,
      default: 6000
    },
    color: {
      type: String,
      required: false,
      default: 'info'
    },
    message: {
      type: String,
      required: true
    }
  },
  computed: {
    model: {
      get () {
        return this.$store.state.snackbar
      },
      set (value) {
        this.$store.commit('setSnackbar', value)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.message {
  margin: auto;
}
</style>
