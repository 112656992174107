<template>
  <ul class="dropdown" aria-label="submenu">
    <li>
      <span
        class="country-background"
        :style="`background-image:url(/img/flags/${currentMarket.country}.svg)`"
      />
      <strong>
        {{ currentMarket.desc }}
      </strong>

      <i class="v-icon icon-menu-down" />
      <ul class="elevation-4">
        <li v-for="market in availableMarkets" :key="market.id">
          <nuxt-link
            class="d-block d-lg-inline-block"
            :to="`/${market.default_language}-${market.country}`"
          >
            <span
              class="country-background"
              :style="`background-image:url(/img/flags/${market.country}.svg)`"
            />
            {{ market.desc }}
          </nuxt-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { trackEvent } from '@/utils/tracking'

export default {
  name: 'MarketSelector',
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    },
    availableMarkets () {
      return this.$t('markets').filter(
        i => i.country !== this.$i18n.locale.substring(3, 5)
      )
    },
    currentMarket () {
      return this.$t('markets').find(
        i => i.country === this.$i18n.locale.substring(3, 5)
      )
    }
  },
  methods: {
    switchMarket (market) {
      const currentMarketCountry = this.$i18n.locale.substring(3, 5)
      trackEvent(
        'Country changed',
        { from_country: currentMarketCountry, to_country: market.country },
        this.$auth.user
      )
      this.$router.push(`/${market.default_language}-${market.country}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.markets-menu {
  border: 1px solid $border-color;
  border-radius: $small-radius;
}
.country-background {
  width: 25px;
  height: 30px;
  background-size: 25px 30px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}
.dropdown {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  @media only screen and (max-width: 760px) {
    width: 60%;
  }
}
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
  float: left;
}

li {
  display: block;
  float: left;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
  padding: 10px;
}

li:hover {
  cursor: pointer;
}
li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  right: 0;
  display: none;
}
li:hover > ul,
li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  margin-top: 0.5rem;
  width: 100%;
  @media only screen and (max-width: 760px) {
    position: relative;
  }
}
ul li ul li {
  clear: both;
  width: 100%;
  white-space: nowrap;
  text-align: right;
}
</style>
