import * as rudderanalytics from 'rudder-sdk-js'
import { logger } from '@/utils/logger'

rudderanalytics.load(process.env.RUDDER_WEB_WRITE_KEY, process.env.RUDDER_PLANE_URI)
window.rudderanalytics = rudderanalytics

export default ({ app }) => {
  app.router.afterEach((to, from) => {
    window.rudderanalytics.page(to.fullPath)
    logger('Tracking event sent: Page view, ' + to.fullPath)
  })
}
