export const state = () => ({
  stats: [],
  breadcrumbs: [],
  snackbar: false,
  snackbarMsg: '',
  notificationsResponse: { data: [] },
  showNotificationsDot: false,
  numberOfUnseenNotifications: 0,
  isMobile: false,
  currentLang: ''
})

export const actions = {
  markAsSeen ({ commit }, notificationId) {
    this.$axios.$post(`/notifications/${notificationId}/see`).then((seenResponse) => {
      commit('markAsSeen', notificationId)
    })
  }
}

export const mutations = {
  markAllAsSeen (state) {
    state.notificationsResponse.data.forEach((notification) => {
      notification.seen = true
    })
    this.commit('setShowNotificationsDot', false)
  },

  markAsSeen (state, notificationId) {
    const notification = state.notificationsResponse.data.find(
      n => n.id === notificationId
    )
    if (notification) {
      notification.seen = true
      if (notification.id === notificationId && !notification.seen) { state.numberOfUnseenNotifications = Math.max(0, state.numberOfUnseenNotifications - 1) }
      if (state.numberOfUnseenNotifications === 0) { this.showNotificationsDot = false }
    }
  },

  updateBreadcrumbs (state, value) {
    state.breadcrumbs = value
  },
  updateStats (state, value) {
    state.stats = value
  },
  setSnackbar (state, value) {
    state.snackbar = value
  },
  setSnackbarColor (state, value) {
    state.snackbarColor = value
  },
  setSnackbarMsg (state, value) {
    state.snackbarMsg = value
  },
  setNotificationsResponse (state, value) {
    state.notificationsResponse = value
  },
  setShowNotificationsDot (state, value) {
    state.showNotificationsDot = value
  },
  setNumberOfUnseenNotifications (state, value) {
    state.numberOfUnseenNotifications = value
  },
  setIsMobile (state, value) {
    state.isMobile = value
  },
  setCurrentLang (state, value) {
    state.currentLang = value
  }
}
