<template>
  <div :class="{ 'mb-2': source === 'menu' }">
    <div v-if="notificationsDisplay.length">
      <v-row class="ma-0" no-gutters>
        <v-col cols="10">
          <h3 v-if="source === 'menu'" class="mb-2 ms-2">
            {{ $t('notifications.title') }}
          </h3>
          <h1 v-if="source === 'page'" class="mb-2">
            {{ $t('notifications.title') }}
          </h1>
        </v-col>
        <v-col cols="2" class="pe-2" align="end">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                :aria-label="$t('globals.options')"
                icon
                class="align-self-start"
                v-on="on"
              >
                <i class="v-icon icon-dots-horizontal" />
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="markAllAsSeen()">
                <v-list-item-icon class="ma-2 me-0 ms-0">
                  <i class="mt-1 v-icon icon-small icon-radiobox-marked" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('notifications.markAllAsSeen') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-divider />

      <v-list class="ma-0 pa-0">
        <div
          v-for="(notification, index) in notificationsDisplay"
          :key="notification.id"
        >
          <v-list-item
            link
            :class="{
              'blue lighten-5': !notification.seen,
              'pa-0': source === 'page',
              'ps-2 pe-2': source === 'menu',
            }"
          >
            <v-list-item-content>
              <v-list-item-title
                class="no-wrap"
                :class="{ 'ps-2 pe-2': source !== 'menu' }"
                @click="openNotification(notification)"
              >
                <div v-if="notification.name === 'answer:added'">
                  <i class="v-icon icon-small icon-gavel" />
                  {{ $t('notifications.answerAdded') }}
                  <strong>{{ notification.data.question_title }}</strong>
                </div>
                <div v-if="notification.name === 'answer:accepted'">
                  <i class="v-icon icon-small icon-star" />
                  {{ $t('notifications.answerAccepted') }}
                  <strong>{{ notification.data.question_title }}</strong>
                </div>
                <div v-if="notification.name === 'comment:added'">
                  <i class="v-icon icon-small icon-comment-text" />
                  <span v-if="!notification.data.answer_id">
                    {{ $t('notifications.commentAddedOnConsultation') }}
                    <strong>{{ notification.data.question_title }}</strong>
                  </span>
                  <span v-else>
                    {{ $t('notifications.commentAddedOnAnswer') }}
                    <strong>{{ notification.data.question_title }}</strong>
                  </span>
                </div>
                <div v-if="notification.name === 'question:added'">
                  <i
                    class="yellow--text text--darken-2 v-icon icon-small icon-flash-circle"
                  />
                  {{ $t('notifications.questionAdded') }}
                  <strong>{{ notification.data.question_title }}</strong>
                </div>
                <div v-if="notification.name === 'question:paid'">
                  <i
                    class="yellow--text text--darken-2 v-icon icon-small icon-flash-circle"
                  />
                  {{ $t('notifications.questionPaid') }}
                  <strong>{{ notification.data.question_title }}</strong>
                </div>
                <div v-if="notification.name === 'answer:paid'">
                  <i
                    class="yellow--text text--darken-2 v-icon icon-small icon-flash-circle"
                  />
                  {{ $t('notifications.answerPaid') }}
                  <strong>{{ notification.data.question_title }}</strong>
                </div>
                <div v-if="notification.name === 'answer:unlocked'">
                  <i
                    class="yellow--text text--darken-2 v-icon icon-small icon-flash-circle"
                  />
                  {{ $t('notifications.answerUnlocked') }}
                  <strong>{{ notification.data.question_title }}</strong>
                </div>
                <div v-if="notification.name === 'expert-profile:created'">
                  <i class="v-icon icon-small icon-check-circle'" />
                  {{ $t('notifications.expertProfileCreated') }}
                </div>
                <div v-if="notification.name === 'expert-profile:accepted'">
                  <i class="v-icon icon-small icon-check'" />
                  {{ $t('notifications.expertProfileAccepted') }}
                </div>
                <div v-if="notification.name === 'expert-profile:refused'">
                  <i class="v-icon icon-small icon-close'" />
                  {{ $t('notifications.expertProfileRefused') }}
                </div>
              </v-list-item-title>
              <v-list-item-subtitle
                :class="{ 'ps-2 pe-2': source !== 'menu' }"
                @click="openNotification(notification)"
              >
                <span class="grey--text text--darken-1 caption">
                  <i
                    class="grey--text text--darken-1 v-icon icon-small icon-clock-outline"
                  />
                  {{ notification.localTime }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="!notification.seen" align="start">
              <v-btn
                icon
                :aria-label="$t('notifications.markAsSeen')"
                :title="$t('notifications.markAsSeen')"
                @click="markAsSeen(notification)"
              >
                <i class="v-icon icon-small icon-radiobox-blank" />
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="notifications && index + 1 < notificationsDisplay.length"
            :key="index"
          />
        </div>
      </v-list>

      <small
        v-if="source === 'menu' && numOfNotifications > 5"
        class="d-block mt-4 ms-2"
      >
        {{ $t('notifications.showing') }} {{ notificationsDisplay.length }}
        {{ $t('notifications.from') }} {{ numOfNotifications }}
        {{ $t(getProperLabel(numOfNotifications, 'Notifications')) }}
        <span v-if="numOfUnseenNotifications > 0">
          ({{ numOfUnseenNotifications }} {{ $t('notifications.new') }})
        </span>
        <nuxt-link :to="localePath({ name: 'notifications' })">
          {{ $t('notifications.allNotifications') }}
        </nuxt-link>
      </small>
    </div>

    <span
      v-if="!notificationsDisplay.length"
      class="d-block"
      :class="{ 'ma-3': source === 'page', 'mt-1 ms-3': source === 'menu' }"
    >
      {{ $t('notifications.noNotifications') }}
    </span>
  </div>
</template>

<script>
import { getProperLabel } from '@/utils'

export default {
  name: 'Notifications',

  props: {
    setLastVisit: {
      type: Boolean,
      required: false,
      default: false
    },
    source: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      currentMarketId: this.$t('markets').filter(
        i => i.country === this.$i18n.locale.substring(3, 5)
      )[0].id,
      notificationsLoaded: false,
      numOfNotifications: 0,
      notificationsDisplay: [],
      numOfUnseenNotifications: 0,
      getProperLabel
    }
  },

  computed: {
    notifications () {
      return this.$store.state.notificationsResponse || {}
    }
  },
  mounted () {
    this.$dayjs.locale(this.$store.state.currentLang)
    this.getNotifications()
    this.$nextTick(() => {
      window.setInterval(() => {
        this.getNotifications()
      }, 5000)
    })
    if (this.setLastVisit) {
      this.countVisit()
    }
  },

  methods: {
    getNotifications () {
      if (
        this.notifications &&
        this.notifications.data &&
        this.notifications.data.length
      ) {
        this.notifications.data.forEach((notification) => {
          notification.localTime = this.$dayjs
            .utc(notification.created_at)
            .local()
            .fromNow()
          notification.hover = false
        })
        this.numOfNotifications = this.notifications.data.length
        this.numOfUnseenNotifications = this.notifications.data.filter(
          i => i.seen === false
        ).length
        if (this.notifications.data.length > 5 && this.source === 'menu') {
          this.notificationsDisplay = this.notifications.data.slice(0, 5)
        } else {
          this.notificationsDisplay = this.notifications.data
        }
      }
    },
    markAllAsSeen () {
      this.$axios.$post('/notifications/mine/see').then((seenResponse) => {
        this.$store.commit('markAllAsSeen')
      })
    },
    markAsSeen (notification) {
      this.$store.dispatch('markAsSeen', notification.id)
    },
    openNotification (notification) {
      this.markAsSeen(notification)
      switch (notification.name) {
        case 'answer:added':
          this.$router.push(
            this.localePath({
              name: 'consultations-id-slug',
              params: {
                id: notification.data.question_id,
                slug: notification.data.question_slug
              }
            }) + `#answer-${notification.data.answer_id}`
          )
          break
        case 'answer:unlocked':
          this.$router.push(
            this.localePath({
              name: 'consultations-id-slug',
              params: {
                id: notification.data.question_id,
                slug: notification.data.question_slug
              }
            }) + `#answer-${notification.data.answer_id}`
          )
          break
        case 'answer:paid':
          this.$router.push(
            this.localePath({
              name: 'consultations-id-slug',
              params: {
                id: notification.data.question_id,
                slug: notification.data.question_slug
              }
            }) + `#answer-${notification.data.answer_id}`
          )
          break
        case 'answer:accepted':
          this.$router.push(
            this.localePath({
              name: 'consultations-id-slug',
              params: {
                id: notification.data.question_id,
                slug: notification.data.question_slug
              }
            }) + `#answer-${notification.data.answer_id}`
          )
          break
        case 'comment:added':
          this.$router.push(
            this.localePath({
              name:
                notification.data.question_type === 'case-study'
                  ? 'cases-id-slug'
                  : 'consultations-id-slug',
              params: {
                id: notification.data.question_id,
                slug: notification.data.question_slug
              }
            }) + `#comment-${notification.data.comment_id}`
          )
          break
        case 'question:paid':
          this.$router.push(
            this.localePath({
              name:
                notification.data.question_type === 'case-study'
                  ? 'cases-id-slug'
                  : 'consultations-id-slug',
              params: {
                id: notification.data.question_id,
                slug: notification.data.question_slug
              }
            })
          )
          break
        case 'question:added':
          this.$axios
            .$get(
              `/services?market_id=${notification.data.market_id}&category_id=${notification.data.category_id}`
            )
            .then((servicesResponse) => {
              servicesResponse.forEach((service) => {
                if (service.name === 'urgent-question') {
                  this.$router.push(
                    this.localePath({
                      name: 'pay',
                      query: {
                        consultationid: notification.data.question_id,
                        serviceid: service.id
                      }

                    }, `${notification.data.receiver_language}-${notification.data.receiver_country}`)
                  )
                }
              })
            })
          break
        case 'expert-profile:created':
          this.$router.push(
            this.localePath({
              name: 'experts-id-username',
              params: {
                id: notification.data.user_id,
                username: notification.data.username
              }
            })
          )
          break
        case 'expert-profile:accepted':
          this.$router.push(
            this.localePath({
              name: 'experts-id-username',
              params: {
                id: this.$auth.user.id,
                username: this.$auth.user.username
              }
            })
          )
          break
        case 'expert-profile:refused':
          this.$router.push(
            this.localePath({
              name: 'experts-id-username',
              params: {
                id: this.$auth.user.id,
                username: this.$auth.user.username
              }
            })
          )
          break
      }
      this.$emit('opened')
    },
    countVisit () {
      localStorage.setItem(
        'notifications_visited',
        this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.no-wrap {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
